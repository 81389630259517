import { useState, useRef, useEffect } from 'react';
import { 
  VStack, 
  Button, 
  Text, 
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel, 
  SimpleGrid,
  useToast,
  HStack,
  Box,
  Badge,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import StationSelectionModal from '../../common/StationSelectionModal';

const AIRPORTS = [
  { id: 'CDG', name: 'Roissy Charles de Gaulle' },
  { id: 'ORLY', name: 'Orly' }
];

const TRAIN_STATIONS = [
  { id: 'GARE_DU_NORD', name: 'Gare du Nord' },
  { id: 'GARE_DE_LYON', name: 'Gare de Lyon' },
  { id: 'GARE_MONTPARNASSE', name: 'Gare Montparnasse' },
  { id: 'GARE_DE_LEST', name: "Gare de l'Est" }
];

const DISTRICTS = [
  '1er', '2e', '3e', '4e', '5e',
  '6e', '7e', '8e', '9e', '10e',
  '11e', '12e', '13e', '14e', '15e',
  '16e', '17e', '18e', '19e', '20e'
];

const DISTRICT_STATIONS = {
  '1er': [
    { id: 'CHATELET', name: 'Châtelet' },
    { id: 'PALAIS_ROYAL', name: 'Palais Royal' }
  ],
  '2e': [
    { id: 'BOURSE', name: 'Bourse' },
    { id: 'SENTIER', name: 'Sentier' }
  ],
  '3e': [
    { id: 'REPUBLIQUE', name: 'République' },
    { id: 'ARTS_ET_METIERS', name: 'Arts et Métiers' }
  ],
  '4e': [
    { id: 'HOTEL_DE_VILLE', name: 'Hôtel de Ville' },
    { id: 'SAINT_PAUL', name: 'Saint-Paul' }
  ],
  '5e': [
    { id: 'PANTHEON', name: 'Panthéon' },
    { id: 'PLACE_MONGE', name: 'Place Monge' }
  ],
  '6e': [
    { id: 'SAINT_GERMAIN', name: 'Saint-Germain-des-Prés' },
    { id: 'LUXEMBOURG', name: 'Luxembourg' }
  ],
  '7e': [
    { id: 'INVALIDES', name: 'Invalides' },
    { id: 'TOUR_EIFFEL', name: 'Tour Eiffel' }
  ],
  '8e': [
    { id: 'CHAMPS_ELYSEES', name: 'Champs-Élysées' },
    { id: 'MADELEINE', name: 'Madeleine' }
  ],
  '9e': [
    { id: 'OPERA', name: 'Opéra' },
    { id: 'GRANDS_BOULEVARDS', name: 'Grands Boulevards' }
  ],
  '10e': [
    { id: 'GARE_DU_NORD', name: 'Gare du Nord' },
    { id: 'GARE_DE_LEST', name: "Gare de l'Est" }
  ],
  '11e': [
    { id: 'BASTILLE', name: 'Bastille' },
    { id: 'NATION', name: 'Nation' }
  ],
  '12e': [
    { id: 'GARE_DE_LYON', name: 'Gare de Lyon' },
    { id: 'BERCY', name: 'Bercy' }
  ],
  '13e': [
    { id: 'PLACE_ITALIE', name: "Place d'Italie" },
    { id: 'BIBLIOTHEQUE', name: 'Bibliothèque François Mitterrand' }
  ],
  '14e': [
    { id: 'MONTPARNASSE', name: 'Montparnasse' },
    { id: 'ALESIA', name: 'Alésia' }
  ],
  '15e': [
    { id: 'CONVENTION', name: 'Convention' },
    { id: 'VAUGIRARD', name: 'Vaugirard' }
  ],
  '16e': [
    { id: 'TROCADERO', name: 'Trocadéro' },
    { id: 'PASSY', name: 'Passy' }
  ],
  '17e': [
    { id: 'TERNES', name: 'Ternes' },
    { id: 'BATIGNOLLES', name: 'Batignolles' }
  ],
  '18e': [
    { id: 'MONTMARTRE', name: 'Montmartre' },
    { id: 'SACRE_COEUR', name: 'Sacré-Cœur' }
  ],
  '19e': [
    { id: 'BUTTES_CHAUMONT', name: 'Buttes-Chaumont' },
    { id: 'LA_VILLETTE', name: 'La Villette' }
  ],
  '20e': [
    { id: 'PERE_LACHAISE', name: 'Père Lachaise' },
    { id: 'BELLEVILLE', name: 'Belleville' }
  ]
};

// Coordonnées GPS des stations pour le calcul des distances
const STATIONS_COORDINATES = {
  // Gares
  'GARE_DU_NORD': { lat: 48.87684, lng: 2.36078 },
  'GARE_DE_LYON': { lat: 48.84511, lng: 2.37343 },
  'GARE_MONTPARNASSE': { lat: 48.84061, lng: 2.31744 },
  'GARE_DE_LEST': { lat: 48.87694, lng: 2.35838 },
  
  // Aéroports
  'CDG': { lat: 49.00406, lng: 2.57110 },
  'ORLY': { lat: 48.72333, lng: 2.37944 },
  
  // Stations d'arrondissements
  'CHATELET': { lat: 48.85847, lng: 2.34679 },
  'PALAIS_ROYAL': { lat: 48.86370, lng: 2.33760 },
  'BOURSE': { lat: 48.86870, lng: 2.34120 },
  'BASTILLE': { lat: 48.85309, lng: 2.36985 },
  'REPUBLIQUE': { lat: 48.8673, lng: 2.3637 },
  'ARTS_ET_METIERS': { lat: 48.8657, lng: 2.3557 },
  'HOTEL_DE_VILLE': { lat: 48.8570, lng: 2.3508 },
  'SAINT_PAUL': { lat: 48.8554, lng: 2.3615 },
  'PANTHEON': { lat: 48.8463, lng: 2.3445 },
  'PLACE_MONGE': { lat: 48.8428, lng: 2.3520 },
  'SAINT_GERMAIN': { lat: 48.8539, lng: 2.3338 },
  'LUXEMBOURG': { lat: 48.8462, lng: 2.3371 },
  'INVALIDES': { lat: 48.8589, lng: 2.3137 },
  'TOUR_EIFFEL': { lat: 48.8584, lng: 2.2945 },
  'CHAMPS_ELYSEES': { lat: 48.8728, lng: 2.3064 },
  'MADELEINE': { lat: 48.8700, lng: 2.3245 },
  'OPERA': { lat: 48.8709, lng: 2.3317 },
  'GRANDS_BOULEVARDS': { lat: 48.8713, lng: 2.3441 },
  'NATION': { lat: 48.8483, lng: 2.3962 },
  'BERCY': { lat: 48.8399, lng: 2.3789 },
  'PLACE_ITALIE': { lat: 48.8322, lng: 2.3558 },
  'BIBLIOTHEQUE': { lat: 48.8297, lng: 2.3755 },
  'MONTPARNASSE': { lat: 48.8428, lng: 2.3219 },
  'ALESIA': { lat: 48.8280, lng: 2.3266 },
  'CONVENTION': { lat: 48.8375, lng: 2.2985 },
  'VAUGIRARD': { lat: 48.8390, lng: 2.3016 },
  'TROCADERO': { lat: 48.8632, lng: 2.2875 },
  'PASSY': { lat: 48.8572, lng: 2.2852 },
  'TERNES': { lat: 48.8780, lng: 2.2983 },
  'BATIGNOLLES': { lat: 48.8839, lng: 2.3149 },
  'MONTMARTRE': { lat: 48.8867, lng: 2.3431 },
  'SACRE_COEUR': { lat: 48.8867, lng: 2.3425 },
  'BUTTES_CHAUMONT': { lat: 48.8787, lng: 2.3825 },
  'LA_VILLETTE': { lat: 48.8847, lng: 2.3873 },
  'PERE_LACHAISE': { lat: 48.8614, lng: 2.3933 },
  'BELLEVILLE': { lat: 48.8717, lng: 2.3797 }
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Rayon de la Terre en km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
};

const getAllStations = () => {
  // Les gares
  const trainStations = TRAIN_STATIONS;
  
  // Les aéroports
  const airports = AIRPORTS;
  
  // Toutes les stations de tous les arrondissements
  const districtStations = Object.values(DISTRICT_STATIONS)
    .flat()
    .filter(station => !trainStations.find(t => t.id === station.id));

  // Combiner toutes les stations et ne garder que celles qui ont des coordonnées
  const allStations = [...trainStations, ...airports, ...districtStations];
  return allStations.filter(station => STATIONS_COORDINATES[station.id]);
};

const sortByDistance = (userLat, userLng, stations) => {
  return stations.sort((a, b) => {
    const distA = calculateDistance(userLat, userLng, 
      STATIONS_COORDINATES[a.id].lat, 
      STATIONS_COORDINATES[a.id].lng
    );
    const distB = calculateDistance(userLat, userLng, 
      STATIONS_COORDINATES[b.id].lat, 
      STATIONS_COORDINATES[b.id].lng
    );
    return distA - distB;
  });
};

const findNearestStation = (userLat, userLng) => {
  let nearestStation = null;
  let minDistance = Infinity;

  Object.entries(STATIONS_COORDINATES).forEach(([stationId, coords]) => {
    const distance = calculateDistance(userLat, userLng, coords.lat, coords.lng);
    if (distance < minDistance) {
      minDistance = distance;
      nearestStation = stationId;
    }
  });

  return nearestStation;
};

const SelectStation = ({ type, rideType, onChange }) => {
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [nearestStation, setNearestStation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stationDistances, setStationDistances] = useState({});
  const stationsRef = useRef(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLat = position.coords.latitude;
          const userLng = position.coords.longitude;
          
          // Obtenir toutes les stations et les trier par distance
          const allStations = getAllStations();
          const sortedStations = sortByDistance(userLat, userLng, allStations);
          
          // Calculer les distances pour toutes les stations
          const distances = {};
          allStations.forEach(station => {
            distances[station.id] = calculateDistance(
              userLat, 
              userLng, 
              STATIONS_COORDINATES[station.id].lat, 
              STATIONS_COORDINATES[station.id].lng
            );
          });
          
          // Trouver le nom de la station la plus proche
          const nearest = sortedStations[0];
          const nearestStationData = {
            ...nearest,
            name: TRAIN_STATIONS.find(s => s.id === nearest.id)?.name || 
                  Object.values(DISTRICT_STATIONS)
                    .flat()
                    .find(s => s.id === nearest.id)?.name
          };
          
          setStationDistances(distances);
          setNearestStation(nearestStationData);
        },
        (error) => {
          console.error("Erreur de géolocalisation:", error);
        }
      );
    }
  }, []);

  const showAirports = (rideType === 'fromAirport' && type === 'departure') || 
                      (rideType === 'toAirport' && type === 'destination');
                      
  const showTrainStations = (rideType === 'fromAirport' && type === 'destination') || 
                           (rideType === 'toAirport' && type === 'departure');

  return (
    <VStack spacing={4} align="stretch" width="100%">
      <Text fontSize="xl" fontWeight="bold">
        {type === 'departure' ? 'Point de départ' : 'Destination'}
      </Text>

      {/* Bouton de station la plus proche - Ne pas afficher pour les aéroports */}
      {!showAirports && (
        <Button
          leftIcon={<FontAwesomeIcon icon={faLocationDot} />}
          colorScheme="green"
          variant="outline"
          width="100%"
          onClick={() => {
            if ("geolocation" in navigator) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const userLat = position.coords.latitude;
                  const userLng = position.coords.longitude;
                  
                  // Obtenir toutes les stations et les trier par distance
                  const allStations = getAllStations();
                  const sortedStations = sortByDistance(userLat, userLng, allStations);
                  
                  // Calculer les distances pour toutes les stations
                  const distances = {};
                  allStations.forEach(station => {
                    distances[station.id] = calculateDistance(
                      userLat, 
                      userLng, 
                      STATIONS_COORDINATES[station.id].lat, 
                      STATIONS_COORDINATES[station.id].lng
                    );
                  });
                  
                  // Trouver le nom de la station la plus proche
                  const nearest = sortedStations[0];
                  const nearestStationData = {
                    ...nearest,
                    name: TRAIN_STATIONS.find(s => s.id === nearest.id)?.name || 
                          Object.values(DISTRICT_STATIONS)
                            .flat()
                            .find(s => s.id === nearest.id)?.name
                  };
                  
                  setStationDistances(distances);
                  setNearestStation(nearestStationData);
                  setIsModalOpen(true);
                },
                (error) => {
                  console.error("Erreur de géolocalisation:", error);
                  alert("Erreur lors de la géolocalisation. Veuillez activer la géolocalisation dans votre navigateur.");
                }
              );
            } else {
              alert("La géolocalisation n'est pas supportée par votre navigateur.");
            }
          }}
        >
          {nearestStation ? `Station la plus proche : ${nearestStation.name}` : "Trouver la station la plus proche"}
        </Button>
      )}
      
      <Tabs variant="enclosed" width="100%">
        <TabList>
          {showAirports && <Tab>Aéroports</Tab>}
          {showTrainStations && <Tab>Gares</Tab>}
          {showTrainStations && <Tab>Arrondissements</Tab>}
        </TabList>

        <TabPanels>
          {showAirports && (
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                {AIRPORTS.map((airport) => (
                  <Button
                    key={airport.id}
                    onClick={() => onChange(airport.id)}
                    size="lg"
                    variant="outline"
                    justifyContent="flex-start"
                    h="auto"
                    p={4}
                    whiteSpace="normal"
                    textAlign="left"
                  >
                    <VStack align="start" spacing={1}>
                      <Text fontWeight="bold">{airport.name}</Text>
                    </VStack>
                  </Button>
                ))}
              </SimpleGrid>
            </TabPanel>
          )}
          
          {showTrainStations && (
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                {TRAIN_STATIONS.map((station) => (
                  <Button
                    key={station.id}
                    onClick={() => onChange(station.id)}
                    size="lg"
                    variant="outline"
                    justifyContent="flex-start"
                    h="auto"
                    p={4}
                    whiteSpace="normal"
                    textAlign="left"
                  >
                    <VStack align="start" spacing={1}>
                      <Text fontWeight="bold">{station.name}</Text>
                    </VStack>
                  </Button>
                ))}
              </SimpleGrid>
            </TabPanel>
          )}

          {showTrainStations && (
            <TabPanel>
              <VStack spacing={4}>
                <SimpleGrid columns={{ base: 3, md: 5 }} spacing={2}>
                  {DISTRICTS.map((district) => (
                    <Button
                      key={district}
                      onClick={() => {
                        setSelectedDistrict(district);
                        setTimeout(() => {
                          stationsRef.current?.scrollIntoView({ behavior: 'smooth' });
                        }, 100);
                      }}
                      size="md"
                      height="40px"
                      variant={selectedDistrict === district ? "solid" : "outline"}
                      colorScheme={selectedDistrict === district ? "blue" : "gray"}
                      borderRadius="lg"
                      fontSize="sm"
                      fontWeight="medium"
                    >
                      {district}
                    </Button>
                  ))}
                </SimpleGrid>
                
                {selectedDistrict && (
                  <Box ref={stationsRef}>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                      {DISTRICT_STATIONS[selectedDistrict]?.map((station) => (
                        <Button
                          key={station.id}
                          onClick={() => onChange(station.id)}
                          size="lg"
                          variant="outline"
                          justifyContent="flex-start"
                          h="auto"
                          p={4}
                          whiteSpace="normal"
                          textAlign="left"
                        >
                          <VStack align="start" spacing={1}>
                            <Text fontWeight="bold">{station.name}</Text>
                          </VStack>
                        </Button>
                      ))}
                    </SimpleGrid>
                  </Box>
                )}
              </VStack>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>

      <StationSelectionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        nearestStation={nearestStation}
        stations={getAllStations()}
        onSelectStation={onChange}
        distances={stationDistances}
      />
    </VStack>
  );
};

export default SelectStation;
