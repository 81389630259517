import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Avatar,
  Badge,
  IconButton,
  Tooltip,
  useColorModeValue,
  Divider,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserPlus,
  faShare,
  faTrash,
  faMapMarkerAlt,
  faPlane,
  faCalendar,
  faTicket,
  faEuroSign,
  faStar,
  faLocationArrow
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase.config';
import RideMap from '../map/RideMap';
import NavigationModal from '../common/NavigationModal';
import { calculatePricePerPerson, AIRPORT_PRICES } from '../../config/prices';

library.add(faUserPlus, faShare, faTrash, faMapMarkerAlt, faPlane, faCalendar, faTicket, faEuroSign, faStar, faLocationArrow);

const MAX_SEATS = 4;

const formatStation = (stationId) => {
  const stations = {
    CDG: 'Roissy Charles de Gaulle',
    ORLY: 'Orly',
    GARE_DU_NORD: 'Gare du Nord',
    GARE_DE_LYON: 'Gare de Lyon',
    GARE_MONTPARNASSE: 'Gare Montparnasse',
    GARE_DE_LEST: "Gare de l'Est"
  };
  
  if (stationId.startsWith('PARIS_')) {
    return `Paris ${stationId.replace('PARIS_', '')}`;
  }
  
  return stations[stationId] || stationId;
};

const getRive = (district) => {
  if (district.startsWith('PARIS_')) {
    const arrondissement = parseInt(district.replace('PARIS_', ''));
    return arrondissement <= 8 ? 'RIVE_DROITE' : 'RIVE_GAUCHE';
  }
  return 'RIVE_DROITE';
};

const MotionBox = motion(Box);

const RideCard = ({ ride, onJoin, onShare, onDelete, onMessage, isUserRide, currentUser }) => {
  const [participants, setParticipants] = useState(ride.participants || []);
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const secondaryBgColor = useColorModeValue('gray.50', 'gray.700');
  const { isOpen: isNavigationOpen, onOpen: onNavigationOpen, onClose: onNavigationClose } = useDisclosure();

  useEffect(() => {
    // Écouter les changements sur le document du trajet
    const unsubscribe = onSnapshot(doc(db, 'rides', ride.id), (doc) => {
      if (doc.exists()) {
        const updatedRide = doc.data();
        setParticipants(updatedRide.participants || []);
      }
    });

    return () => unsubscribe();
  }, [ride.id]);

  const isCreator = currentUser && ride.createdBy === currentUser.email;
  const isParticipant = currentUser && participants.includes(currentUser.email);
  const remainingSeats = MAX_SEATS - (participants.length || 0);
  const isFull = remainingSeats <= 0;

  // Calculer les prix en fonction du type de trajet
  console.log('Debug RideCard - ride:', {
    type: ride.type,
    departure: ride.departure,
    destination: ride.destination,
    participants: participants.length || 0
  });

  const airport = ride.type === 'toAirport' ? ride.destination : ride.departure;
  const district = ride.type === 'toAirport' ? ride.departure : ride.destination;
  const rive = getRive(district);
  const basePrice = AIRPORT_PRICES[airport][rive];
  
  const currentPassengers = participants.length || 1;
  
  // Prix si la personne voyage seule
  const soloPrice = basePrice;
  
  // Prix si la personne rejoint le trajet
  const priceIfJoined = Math.ceil(basePrice / (currentPassengers + 1));
  
  // Calcul de l'économie
  const savings = soloPrice - priceIfJoined;
  const savingsPercentage = Math.round((savings / soloPrice) * 100);

  return (
    <MotionBox
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.2 }}
      bg={bgColor}
      p={4}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      shadow="sm"
      position="relative"
      overflow="hidden"
    >
      <VStack align="stretch" spacing={4}>
        {/* En-tête */}
        <HStack justify="space-between" align="start">
          <VStack align="start" spacing={2}>
            <VStack align="start" spacing={1}>
              <HStack>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <Text fontWeight="bold">
                  {formatStation(ride.departure)}
                </Text>
              </HStack>
              <HStack>
                <FontAwesomeIcon icon={faPlane} />
                <Text fontWeight="bold">
                  {formatStation(ride.destination)}
                </Text>
              </HStack>
              <HStack>
                <FontAwesomeIcon icon={faCalendar} />
                <Text fontSize="sm" color={textColor}>
                  {new Date(ride.date).toLocaleDateString('fr-FR', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                  {' à '}
                  {ride.time}
                </Text>
              </HStack>
              <HStack>
                <FontAwesomeIcon icon={faTicket} />
                <Text fontSize="sm">
                  Places restantes : {remainingSeats}/4
                </Text>
              </HStack>
              <HStack>
                <FontAwesomeIcon icon={faEuroSign} />
                <Text fontSize="sm">
                  Prix si vous rejoignez : {priceIfJoined}€ (au lieu de {soloPrice}€)
                </Text>
              </HStack>
              <HStack>
                <FontAwesomeIcon icon={faStar} />
                <Text fontSize="sm">
                  Économie : {savings}€ ({savingsPercentage}%)
                </Text>
              </HStack>
            </VStack>
          </VStack>
          <Avatar
            size="sm"
            name={ride.creatorName}
            src={ride.creatorPhotoURL}
          />
        </HStack>

        {/* Carte du trajet */}
        <Box h="200px" borderRadius="md" overflow="hidden">
          <RideMap from={ride.departure} to={ride.destination} />
        </Box>

        <Divider />

        {/* Informations */}
        <HStack justify="space-between" wrap="wrap" spacing={4}>
          <VStack align="start" spacing={1}>
            <Text fontSize="sm" color={textColor}>
              Créé par {isCreator ? 'vous' : ride.creatorName}
            </Text>
            <Badge colorScheme={isFull ? 'red' : 'green'}>
              {isFull ? 'Complet' : `${remainingSeats} place${remainingSeats > 1 ? 's' : ''} disponible${remainingSeats > 1 ? 's' : ''}`}
            </Badge>
          </VStack>
          <HStack spacing={2} mt={4}>
            {!isUserRide && (
              <Tooltip label="Rejoindre le trajet">
                <IconButton
                  icon={<FontAwesomeIcon icon={faUserPlus} />}
                  onClick={onJoin}
                  colorScheme="blue"
                  variant="ghost"
                />
              </Tooltip>
            )}
            
            <Tooltip label="Partager">
              <IconButton
                icon={<FontAwesomeIcon icon={faShare} />}
                onClick={onShare}
                colorScheme="green"
                variant="ghost"
              />
            </Tooltip>

            <Tooltip label="Rejoindre le point de départ">
              <IconButton
                icon={<FontAwesomeIcon icon={faLocationArrow} />}
                onClick={onNavigationOpen}
                colorScheme="purple"
                variant="ghost"
              />
            </Tooltip>
            
            {isUserRide && onDelete && (
              <Tooltip label="Supprimer">
                <IconButton
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  onClick={onDelete}
                  colorScheme="red"
                  variant="ghost"
                />
              </Tooltip>
            )}
          </HStack>
        </HStack>

        {/* Prix et type de trajet */}
        <HStack 
          justify="space-between" 
          bg={secondaryBgColor} 
          p={2} 
          borderRadius="md"
        >
          <Text fontSize="sm" fontWeight="bold">
            {priceIfJoined}€ par personne
          </Text>
          <Badge>
            {ride.type === 'toAirport' ? 'Vers l\'aéroport' : 'Depuis l\'aéroport'}
          </Badge>
        </HStack>
      </VStack>

      <NavigationModal 
        isOpen={isNavigationOpen}
        onClose={onNavigationClose}
        ride={ride}
      />
    </MotionBox>
  );
};

export default RideCard;
