// Définition des arrondissements par rive
const RIVE_DROITE = ['1', '2', '3', '4', '8', '9', '10', '11', '12', '16', '17', '18', '19', '20'];
const RIVE_GAUCHE = ['5', '6', '7', '13', '14', '15'];

// Tarifs fixes pour les trajets
export const AIRPORT_PRICES = {
  CDG: {
    RIVE_DROITE: 56,
    RIVE_GAUCHE: 65
  },
  ORLY: {
    RIVE_DROITE: 44,
    RIVE_GAUCHE: 36
  }
};

// Déterminer si un arrondissement est rive droite ou gauche
const getRive = (district) => {
  const districtNumber = district.replace(/[^0-9]/g, '');
  return RIVE_DROITE.includes(districtNumber) ? 'RIVE_DROITE' : 'RIVE_GAUCHE';
};

// Calculer le prix par personne en fonction du nombre total de passagers
export const calculatePricePerPerson = (airport, district, totalPassengers) => {
  const rive = getRive(district);
  const basePrice = AIRPORT_PRICES[airport][rive];
  return Math.ceil(basePrice / totalPassengers);
};
