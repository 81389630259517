import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  VStack,
  Input,
  Button,
  Text,
  HStack,
  IconButton,
  useColorModeValue,
  Avatar,
  Wrap,
  WrapItem,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase.config';
import { sendMessage, getMessages, markMessagesAsRead, QUICK_MESSAGES } from '../services/chat.service';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const RideMessages = () => {
  const { rideId } = useParams();
  const [user] = useAuthState(auth);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const navigate = useNavigate();

  useEffect(() => {
    if (user && rideId) {
      loadMessages();
      const interval = setInterval(loadMessages, 5000); // Rafraîchir toutes les 5 secondes
      return () => clearInterval(interval);
    }
  }, [user, rideId]);

  const loadMessages = async () => {
    try {
      const fetchedMessages = await getMessages(rideId);
      setMessages(fetchedMessages);
      await markMessagesAsRead(rideId, user.email);
      scrollToBottom();
    } catch (error) {
      console.error('Error loading messages:', error);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    setLoading(true);
    try {
      await sendMessage(rideId, user.email, newMessage.trim());
      setNewMessage('');
      await loadMessages();
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'envoyer le message',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleQuickMessage = async (message) => {
    setLoading(true);
    try {
      await sendMessage(rideId, user.email, message);
      await loadMessages();
    } catch (error) {
      console.error('Error sending quick message:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'envoyer le message rapide',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box p={4} h="100vh">
      <HStack mb={4} align="center">
        <IconButton
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => navigate('/messages')}
          variant="ghost"
          aria-label="Retour aux messages"
          size="md"
        />
        <Text fontSize="xl" fontWeight="bold">Messages du trajet</Text>
      </HStack>

      <VStack
        spacing={4}
        h="calc(100vh - 180px)"
        bg={bgColor}
        borderRadius="lg"
        borderWidth="1px"
        borderColor={borderColor}
        p={4}
      >
        {/* Quick Messages */}
        <Wrap spacing={2} mb={4}>
          {QUICK_MESSAGES.map((msg) => (
            <WrapItem key={msg.id}>
              <Tooltip label={msg.text}>
                <IconButton
                  icon={<Text>{msg.icon}</Text>}
                  onClick={() => handleQuickMessage(msg.text)}
                  isDisabled={loading}
                  variant="outline"
                />
              </Tooltip>
            </WrapItem>
          ))}
        </Wrap>

        {/* Messages List */}
        <VStack
          flex={1}
          spacing={4}
          overflowY="auto"
          mb={4}
          p={4}
        >
          {messages.map((message, index) => (
            <Box
              key={message.id}
              alignSelf={message.senderEmail === user.email ? 'flex-end' : 'flex-start'}
              maxW="70%"
            >
              <HStack
                spacing={2}
                bg={message.senderEmail === user.email ? 'blue.500' : 'gray.100'}
                color={message.senderEmail === user.email ? 'white' : 'black'}
                p={3}
                borderRadius="lg"
              >
                {message.senderEmail !== user.email && (
                  <Avatar size="sm" name={message.senderEmail} />
                )}
                <Text>{message.text}</Text>
              </HStack>
              <Text
                fontSize="xs"
                color="gray.500"
                textAlign={message.senderEmail === user.email ? 'right' : 'left'}
              >
                {message.timestamp ? new Date(message.timestamp).toLocaleTimeString() : ''}
              </Text>
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </VStack>

        {/* Message Input */}
        <HStack spacing={2}>
          <Input
            placeholder="Tapez votre message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <IconButton
            icon={<FontAwesomeIcon icon="paper-plane" />}
            onClick={handleSendMessage}
            isLoading={loading}
            colorScheme="blue"
          />
        </HStack>
      </VStack>
    </Box>
  );
};

export default RideMessages;
