import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faPlane, faTaxi, faTrain, faMapMarker, 
  faCalendar, faClock, faUser, faUsers, 
  faHome, faPlus, faSearch, faCheck, faShare,
  faArrowLeft, faMinus, faSignInAlt, faComment,
  faEuroSign, faEdit, faSave, faTimes, faMapMarkerAlt,
  faBell, faLock, faQuestionCircle, faUserCircle,
  faEnvelope, faFileAlt, faShieldAlt, faTrash,
  faInfoCircle, faSpinner, faPaperPlane, faSmile,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import theme from './theme/theme';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/auth/PrivateRoute';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import AuthForm from './components/auth/AuthForm';
import CreateRide from './components/rides/CreateRide';
import SearchRide from './components/rides/SearchRide';
import Profile from './pages/Profile';
import Messages from './pages/Messages';
import RideMessages from './pages/RideMessages';

// Ajout des icônes à la bibliothèque Font Awesome
library.add(
  faPlane, faTaxi, faTrain, faMapMarker,
  faCalendar, faClock, faUser, faUsers,
  faHome, faPlus, faSearch, faCheck, faShare,
  faArrowLeft, faMinus, faSignInAlt, fab,
  faComment, faEuroSign, faEdit, faSave, 
  faTimes, faMapMarkerAlt, faBell, faLock,
  faQuestionCircle, faUserCircle, faEnvelope, 
  faFileAlt, faShieldAlt, faTrash, faInfoCircle,
  faSpinner, faPaperPlane, faSmile, faSignOutAlt
);

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/auth" element={<AuthForm />} />
            <Route
              path="/creer-trajet"
              element={
                <PrivateRoute>
                  <CreateRide />
                </PrivateRoute>
              }
            />
            <Route
              path="/rechercher"
              element={<SearchRide />}
            />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="/messages" element={<PrivateRoute><Messages /></PrivateRoute>} />
            <Route path="/messages/:rideId" element={<PrivateRoute><RideMessages /></PrivateRoute>} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
