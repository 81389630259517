import { VStack, Button, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectRideType = ({ onChange }) => {
  return (
    <VStack spacing={6} w="100%">
      <Text fontSize="xl" fontWeight="bold">
        Sélectionnez votre type de trajet
      </Text>

      <Button
        w="100%"
        size="lg"
        variant="outline"
        leftIcon={<FontAwesomeIcon icon="plane" />}
        onClick={() => onChange('fromAirport')}
      >
        Depuis l'aéroport
      </Button>

      <Button
        w="100%"
        size="lg"
        variant="outline"
        leftIcon={<FontAwesomeIcon icon="taxi" />}
        onClick={() => onChange('toAirport')}
      >
        Vers l'aéroport
      </Button>
    </VStack>
  );
};

export default SelectRideType;
