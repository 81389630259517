import { useState } from 'react';
import {
  Box,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Stack,
  Icon,
  Text,
  useBreakpointValue,
  useColorModeValue,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import PersonalInfo from '../components/profile/sections/PersonalInfo';
import RideHistory from '../components/profile/sections/RideHistory';
import Preferences from '../components/profile/sections/Preferences';
import Security from '../components/profile/sections/Security';
import Support from '../components/profile/sections/Support';

const MotionBox = motion(Box);

const Profile = () => {
  const { user } = useAuth();
  const toast = useToast();
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const tabBg = useColorModeValue('gray.50', 'gray.700');
  const activeTabBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  const tabs = [
    { name: 'Informations', icon: 'user-circle' },
    { name: 'Mes Trajets', icon: 'taxi' },
    { name: 'Préférences', icon: 'bell' },
    { name: 'Sécurité', icon: 'shield-alt' },
    { name: 'Support', icon: 'question-circle' }
  ];

  if (!user) {
    return (
      <Container maxW="container.xl" py={8}>
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          textAlign="center"
          p={8}
          bg={bgColor}
          rounded="xl"
          shadow="lg"
        >
          <Heading size="lg" mb={4}>Connexion requise</Heading>
          <Text>Veuillez vous connecter pour accéder à votre profil.</Text>
        </MotionBox>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <MotionBox
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box
          bg={bgColor}
          rounded="xl"
          shadow="lg"
          overflow="hidden"
          border="1px solid"
          borderColor={borderColor}
        >
          <Tabs 
            index={activeTab} 
            onChange={setActiveTab}
            variant="enclosed"
            colorScheme="blue"
            isLazy
          >
            <TabList 
              bg={tabBg} 
              borderBottomWidth="2px"
              borderBottomColor={borderColor}
              overflowX="auto" 
              overflowY="hidden" 
              whiteSpace="nowrap"
              css={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                scrollbarWidth: 'none',
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  py={4}
                  px={6}
                  fontWeight="semibold"
                  color={textColor}
                  _selected={{
                    bg: activeTabBg,
                    color: 'blue.500',
                    borderBottomWidth: '2px',
                    borderBottomColor: 'blue.500',
                    marginBottom: '-2px',
                  }}
                >
                  <Stack direction="row" spacing={2} align="center">
                    <Icon as={FontAwesomeIcon} icon={tab.icon} />
                    {!isMobile && <Text>{tab.name}</Text>}
                  </Stack>
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              <TabPanel>
                <PersonalInfo user={user} />
              </TabPanel>
              <TabPanel>
                <RideHistory user={user} />
              </TabPanel>
              <TabPanel>
                <Preferences user={user} />
              </TabPanel>
              <TabPanel>
                <Security user={user} />
              </TabPanel>
              <TabPanel>
                <Support user={user} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </MotionBox>
    </Container>
  );
};

export default Profile;
