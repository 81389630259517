import { useState } from 'react';
import {
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Divider,
  Box,
  Heading,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { 
  updatePassword, 
  deleteUser,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signOut
} from 'firebase/auth';
import { auth } from '../../../firebase.config';

const Security = ({ user }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [passwords, setPasswords] = useState({
    current: '',
    new: '',
    confirm: '',
  });

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      toast({
        title: 'Déconnexion réussie',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      navigate('/');
    } catch (error) {
      toast({
        title: 'Erreur de déconnexion',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePasswordChange = async () => {
    if (passwords.new !== passwords.confirm) {
      toast({
        title: 'Erreur',
        description: 'Les nouveaux mots de passe ne correspondent pas',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        passwords.current
      );
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, passwords.new);

      toast({
        title: 'Mot de passe mis à jour',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setPasswords({
        current: '',
        new: '',
        confirm: '',
      });
    } catch (error) {
      console.error('Erreur lors du changement de mot de passe:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de changer le mot de passe',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAccountDeletion = async () => {
    try {
      await deleteUser(user);
      toast({
        title: 'Compte supprimé',
        description: 'Votre compte a été supprimé avec succès',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression du compte:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le compte',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={6} align="stretch" w="100%" p={4}>
      {/* Section Déconnexion */}
      <Box mb={8}>
        <Heading size="md" mb={4}>Déconnexion</Heading>
        <Button
          leftIcon={<FontAwesomeIcon icon="sign-out-alt" />}
          colorScheme="red"
          variant="outline"
          size="lg"
          w="100%"
          onClick={handleSignOut}
          _hover={{
            bg: "red.50",
            transform: "translateY(-2px)",
            boxShadow: "md",
          }}
          transition="all 0.2s"
        >
          Se déconnecter
        </Button>
      </Box>

      <Divider my={6} />

      {/* Section Changement de mot de passe */}
      <Heading size="md" mb={4}>Changement de mot de passe</Heading>
      <FormControl>
        <FormLabel>Mot de passe actuel</FormLabel>
        <Input
          type="password"
          value={passwords.current}
          onChange={(e) =>
            setPasswords({ ...passwords, current: e.target.value })
          }
        />
      </FormControl>

      <FormControl>
        <FormLabel>Nouveau mot de passe</FormLabel>
        <Input
          type="password"
          value={passwords.new}
          onChange={(e) =>
            setPasswords({ ...passwords, new: e.target.value })
          }
        />
      </FormControl>

      <FormControl>
        <FormLabel>Confirmer le nouveau mot de passe</FormLabel>
        <Input
          type="password"
          value={passwords.confirm}
          onChange={(e) =>
            setPasswords({ ...passwords, confirm: e.target.value })
          }
        />
      </FormControl>

      <Button
        leftIcon={<FontAwesomeIcon icon="lock" />}
        colorScheme="blue"
        onClick={handlePasswordChange}
      >
        Mettre à jour le mot de passe
      </Button>

      <Divider my={6} />

      {/* Section Suppression de compte */}
      <Box>
        <Heading size="md" mb={4}>Danger Zone</Heading>
        <Button
          leftIcon={<FontAwesomeIcon icon="trash" />}
          colorScheme="red"
          variant="ghost"
          onClick={onOpen}
        >
          Supprimer mon compte
        </Button>
      </Box>

      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Supprimer le compte ?</AlertDialogHeader>
            <AlertDialogBody>
              Êtes-vous sûr ? Cette action est irréversible.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>Annuler</Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  handleAccountDeletion();
                  onClose();
                }}
              >
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};

export default Security;
