import React, { useState, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Button,
  useToast,
  Text,
  SimpleGrid,
  Box,
  useColorModeValue,
  Center,
  Spinner,
  useClipboard,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { calculatePricePerPerson, AIRPORT_PRICES } from '../../config/prices';
import { formatStation } from '../../utils/formatters';

// Ajouter toutes les icônes de marques
library.add(fab, faEnvelope);

// Constantes pour les couleurs des réseaux sociaux
const socialColors = {
  facebook: {
    bg: '#1877F2',
    hover: '#1664d9'
  },
  twitter: {
    bg: '#1DA1F2',
    hover: '#1a91da'
  },
  linkedin: {
    bg: '#0A66C2',
    hover: '#0959ab'
  },
  whatsapp: {
    bg: '#25D366',
    hover: '#21bd5a'
  },
  telegram: {
    bg: '#0088cc',
    hover: '#0077b3'
  },
  email: {
    bg: '#718096',
    hover: '#4A5568'
  }
};

const ShareModal = ({ isOpen, onClose, ride }) => {
  const toast = useToast();
  const [selectedTab, setSelectedTab] = useState(0);
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const infoBg = useColorModeValue('blue.50', 'blue.900');
  const infoBorderColor = useColorModeValue('blue.200', 'blue.700');
  const inputBg = useColorModeValue('gray.50', 'gray.700');

  const shareUrl = `https://cotaxiage.app/ride/${ride?.id}`;
  const { hasCopied, onCopy } = useClipboard(shareUrl);

  const generateShareText = useCallback((platform = 'default') => {
    if (!ride) return '';
    
    try {
      const date = new Date(ride?.date);
      const formattedDate = date.toLocaleDateString('fr-FR', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
      const formattedTime = date.toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit'
      });

      const currentPassengers = ride?.participants?.length || 1;
      const totalPassengersIfJoined = currentPassengers + 1;

      const isDestinationAirport = ride?.type === 'toAirport';
      const airport = isDestinationAirport ? ride?.destination : ride?.departure;
      const district = 'RIVE_DROITE';

      const basePrice = AIRPORT_PRICES[airport][district];
      const myPrice = Math.ceil(basePrice / totalPassengersIfJoined);
      const savings = basePrice - myPrice;
      const savingsPercentage = Math.round((savings / basePrice) * 100);

      const baseText = `🚖 Partagez un taxi et économisez gros avec CoTaxiage !\n\n` +
        `📍 Départ : ${formatStation(ride?.departure)}\n` +
        `✈️ Arrivée : ${formatStation(ride?.destination)}\n` +
        `📅 ${formattedDate} à ${formattedTime}\n` +
        `🎟️ Places restantes : ${ride?.availableSeats}/4\n` +
        `💰 Prix si vous rejoignez : ${myPrice}€ (au lieu de ${basePrice}€)\n` +
        `✨ Économie : ${savings}€ (${savingsPercentage}%)\n` +
        `👉 Rejoignez mon trajet : ${shareUrl}\n` +
        `🌟 CoTaxiage, 100% gratuit, rapide et éco-friendly. Essayez dès aujourd'hui !\n\n`;

      return baseText;
    } catch (error) {
      console.error('Erreur lors de la génération du texte:', error);
      return 'Erreur lors de la génération du message de partage';
    }
  }, [ride]);

  const handleShare = (platform) => {
    const text = generateShareText(platform);
    const encodedText = encodeURIComponent(text);
    
    switch (platform) {
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?text=${encodedText}`, '_blank');
        break;
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodedText}`, '_blank');
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`, '_blank');
        break;
      case 'whatsapp':
        window.open(`https://api.whatsapp.com/send?text=${encodedText}`, '_blank');
        break;
      case 'telegram':
        window.open(`https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodedText}`, '_blank');
        break;
      case 'email':
        window.open(`mailto:?subject=Partagez un trajet sur CoTaxiage&body=${encodedText}`, '_blank');
        break;
      default:
        navigator.clipboard.writeText(text);
        toast({
          title: "Texte copié !",
          description: "Le message a été copié dans votre presse-papier.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
    }
  };

  if (!ride) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent bg={bgColor}>
          <ModalHeader>Partager ce trajet</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Center py={8}>
              <VStack spacing={4}>
                <Spinner size="xl" />
                <Text>Chargement des informations du trajet...</Text>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent bg={bgColor}>
        <ModalHeader>Partager ce trajet</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Tabs isFitted variant="enclosed" index={selectedTab} onChange={setSelectedTab}>
            <TabList mb="1em">
              <Tab>Réseaux sociaux</Tab>
              <Tab>Code QR</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <VStack spacing={4} align="stretch">
                  <SimpleGrid columns={[2, 3, 4]} spacing={4}>
                    <Button 
                      onClick={() => handleShare('facebook')} 
                      bg={socialColors.facebook.bg} 
                      _hover={{ bg: socialColors.facebook.hover }} 
                      color="white" 
                      leftIcon={<FontAwesomeIcon icon={["fab", "facebook-f"]} />}
                      w="full"
                    >
                      Facebook
                    </Button>
                    <Button 
                      onClick={() => handleShare('twitter')} 
                      bg={socialColors.twitter.bg} 
                      _hover={{ bg: socialColors.twitter.hover }} 
                      color="white" 
                      leftIcon={<FontAwesomeIcon icon={["fab", "twitter"]} />}
                      w="full"
                    >
                      Twitter
                    </Button>
                    <Button 
                      onClick={() => handleShare('linkedin')} 
                      bg={socialColors.linkedin.bg} 
                      _hover={{ bg: socialColors.linkedin.hover }} 
                      color="white" 
                      leftIcon={<FontAwesomeIcon icon={["fab", "linkedin-in"]} />}
                      w="full"
                    >
                      LinkedIn
                    </Button>
                    <Button 
                      onClick={() => handleShare('whatsapp')} 
                      bg={socialColors.whatsapp.bg} 
                      _hover={{ bg: socialColors.whatsapp.hover }} 
                      color="white" 
                      leftIcon={<FontAwesomeIcon icon={["fab", "whatsapp"]} />}
                      w="full"
                    >
                      WhatsApp
                    </Button>
                    <Button 
                      onClick={() => handleShare('telegram')} 
                      bg={socialColors.telegram.bg} 
                      _hover={{ bg: socialColors.telegram.hover }} 
                      color="white" 
                      leftIcon={<FontAwesomeIcon icon={["fab", "telegram"]} />}
                      w="full"
                    >
                      Telegram
                    </Button>
                    <Button 
                      onClick={() => handleShare('email')} 
                      bg={socialColors.email.bg} 
                      _hover={{ bg: socialColors.email.hover }} 
                      color="white" 
                      leftIcon={<FontAwesomeIcon icon={faEnvelope} />}
                      w="full"
                    >
                      Email
                    </Button>
                  </SimpleGrid>

                  <Box p={4} bg={infoBg} borderRadius="md" borderWidth={1} borderColor={infoBorderColor}>
                    <Text whiteSpace="pre-wrap" fontSize="sm">
                      {generateShareText()}
                    </Text>
                  </Box>

                  <Button onClick={() => handleShare('copy')} width="100%" colorScheme="blue">
                    Copier le message
                  </Button>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack spacing={4}>
                  <Box
                    p={4}
                    bg="white"
                    borderRadius="lg"
                    boxShadow="md"
                  >
                    <QRCodeSVG
                      value={shareUrl}
                      size={256}
                      level="H"
                      includeMargin={true}
                    />
                  </Box>
                  <Text fontSize="sm" color="gray.600">
                    Scannez ce code QR pour accéder directement au trajet
                  </Text>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
