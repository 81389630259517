import { auth, db } from '../firebase.config';
import { doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { uploadImageToCloudinary, deleteImageFromCloudinary } from './cloudinary.service';

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

// Fonction utilitaire pour compresser l'image
const compressImage = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 800;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            resolve(new File([blob], file.name, { type: 'image/jpeg' }));
          },
          'image/jpeg',
          0.7
        );
      };
      img.onerror = reject;
    };
    reader.onerror = reject;
  });
};

// Validation du fichier
const validateFile = (file) => {
  if (!file) {
    throw new Error('Aucun fichier sélectionné');
  }

  if (!ALLOWED_TYPES.includes(file.type)) {
    throw new Error('Type de fichier non autorisé. Utilisez JPG, PNG ou GIF');
  }

  if (file.size > MAX_FILE_SIZE) {
    throw new Error(`La taille du fichier ne doit pas dépasser ${MAX_FILE_SIZE / (1024 * 1024)}MB`);
  }

  return true;
};

const ensureUserDocument = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  
  if (!userDoc.exists()) {
    // Créer le document utilisateur s'il n'existe pas
    await setDoc(userRef, {
      id: userId,
      photoURL: null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  }
  return userRef;
};

// Mise à jour du profil utilisateur dans Firestore
const updateUserProfile = async (userRef, photoURL) => {
  await Promise.all([
    updateDoc(userRef, {
      photoURL,
      updatedAt: new Date().toISOString()
    }),
    updateProfile(auth.currentUser, { photoURL })
  ]);
};

export const uploadProfilePhoto = async (file, onProgress = () => {}) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Utilisateur non connecté');

    // S'assurer que le document utilisateur existe
    const userRef = await ensureUserDocument(user.uid);

    // Valider le fichier
    validateFile(file);

    // Compresser l'image
    const compressedFile = await compressImage(file);

    // Upload vers Cloudinary
    const photoURL = await uploadImageToCloudinary(compressedFile, onProgress);

    // Mettre à jour le profil utilisateur
    await updateUserProfile(userRef, photoURL);

    return photoURL;
  } catch (error) {
    console.error('Erreur lors de l\'upload:', error);
    throw error;
  }
};

export const deleteProfilePhoto = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Utilisateur non connecté');

    // S'assurer que le document utilisateur existe
    const userRef = await ensureUserDocument(user.uid);

    // Mettre à jour le profil utilisateur
    await Promise.all([
      updateDoc(userRef, {
        photoURL: null,
        updatedAt: new Date().toISOString()
      }),
      updateProfile(user, { photoURL: null })
    ]);

    return true;
  } catch (error) {
    console.error('Erreur lors de la suppression:', error);
    throw error;
  }
};
