import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Avatar,
  HStack,
  useColorModeValue,
  Skeleton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase.config';
import { getUserRides, getUnreadMessageCount, markMessagesAsRead } from '../services/chat.service';
import { motion } from 'framer-motion';
import { calculatePricePerPerson } from '../config/prices';

const MotionBox = motion(Box);

const Messages = () => {
  const [user] = useAuthState(auth);
  const [rides, setRides] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');

  useEffect(() => {
    let mounted = true;

    const loadRides = async () => {
      if (user) {
        try {
          const userRides = await getUserRides(user.email);
          
          // Dédupliquer les trajets basés sur l'ID
          const uniqueRides = Array.from(
            new Map(userRides.map(ride => [ride.id, ride])).values()
          );

          if (mounted) {
            setRides(uniqueRides);

            // Charger les compteurs de messages non lus pour chaque trajet
            const counts = {};
            await Promise.all(
              uniqueRides.map(async (ride) => {
                try {
                  const count = await getUnreadMessageCount(ride.id, user.email);
                  if (mounted) {
                    counts[ride.id] = count;
                  }
                } catch (error) {
                  console.error(`Error getting unread count for ride ${ride.id}:`, error);
                  if (mounted) {
                    counts[ride.id] = 0;
                  }
                }
              })
            );
            
            if (mounted) {
              setUnreadCounts(counts);
              setLoading(false);
            }
          }
        } catch (error) {
          console.error('Error loading rides:', error);
          if (mounted) {
            setLoading(false);
          }
        }
      }
    };

    loadRides();
    
    // Mettre à jour les compteurs toutes les 30 secondes
    const interval = setInterval(loadRides, 30000);
    
    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [user]);

  const handleRideClick = async (rideId) => {
    try {
      // Marquer les messages comme lus avant de naviguer
      await markMessagesAsRead(rideId, user.email);
      
      // Mettre à jour le compteur local
      setUnreadCounts(prev => ({
        ...prev,
        [rideId]: 0
      }));
      
      // Naviguer vers la conversation
      navigate(`/messages/${rideId}`);
    } catch (error) {
      console.error('Error marking messages as read:', error);
      // Naviguer quand même en cas d'erreur
      navigate(`/messages/${rideId}`);
    }
  };

  // Fonction pour calculer le prix du trajet
  const calculateRidePrice = (ride) => {
    if (!ride.airport || !ride.district) return null;
    const totalPassengers = (ride.participants?.length || 1);
    return calculatePricePerPerson(ride.airport, ride.district, totalPassengers);
  };

  if (loading) {
    return (
      <Box p={4} mb={20}>
        <HStack justify="space-between" mb={6} align="center">
          <Heading size="lg">Messages</Heading>
          <HStack spacing={2}>
            <Skeleton height="20px" width="100px" borderRadius="full" />
            <Skeleton height="20px" width="100px" borderRadius="full" />
          </HStack>
        </HStack>

        <VStack spacing={4} align="stretch">
          {[1, 2, 3].map((i) => (
            <Skeleton key={`skeleton-${i}`} height="100px" borderRadius="lg" />
          ))}
        </VStack>
      </Box>
    );
  }

  return (
    <Box p={4} mb={20}>
      <HStack justify="space-between" mb={6} align="center">
        <Heading size="lg">Messages</Heading>
        <Text fontSize="sm" color="gray.600">
          {rides.filter(ride => new Date(ride.date) > new Date()).length} trajets à venir
        </Text>
      </HStack>

      <VStack spacing={4} align="stretch">
        {rides.length === 0 ? (
          <Box
            p={6}
            textAlign="center"
            bg={bgColor}
            borderRadius="lg"
            borderWidth="1px"
            borderColor={borderColor}
          >
            <Text>Aucun message pour le moment</Text>
          </Box>
        ) : (
          rides.map((ride) => {
            const pricePerPerson = calculateRidePrice(ride);
            return (
              <MotionBox
                key={`ride-${ride.id}`}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => handleRideClick(ride.id)}
                bg={bgColor}
                p={4}
                borderRadius="lg"
                borderWidth="1px"
                borderColor={borderColor}
                cursor="pointer"
                position="relative"
                transition="all 0.2s"
                _hover={{
                  bg: hoverBgColor,
                  borderColor: 'blue.500',
                  shadow: 'md',
                }}
              >
                <HStack spacing={4} align="start">
                  <Avatar 
                    size="md" 
                    name={ride.createdBy === user.email ? 'Vous' : ride.creatorName}
                    src={ride.creatorPhotoURL}
                  />
                  <Box flex={1}>
                    <HStack justify="space-between" mb={2}>
                      <VStack align="start" spacing={1}>
                        <Text fontWeight="bold">
                          {ride.departure} → {ride.destination}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                          {new Date(ride.date).toLocaleDateString('fr-FR', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </Text>
                      </VStack>
                      {unreadCounts[ride.id] > 0 && (
                        <Box
                          bg="red.500"
                          color="white"
                          borderRadius="full"
                          px={2}
                          py={1}
                          fontSize="sm"
                        >
                          {unreadCounts[ride.id]}
                        </Box>
                      )}
                    </HStack>

                    <HStack mt={2} spacing={4}>
                      <Text fontSize="sm" color="gray.600">
                        {ride.role === 'creator' ? 'Créateur' : 'Participant'}
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {ride.participants?.length || 1} participant{(ride.participants?.length || 1) > 1 ? 's' : ''}
                      </Text>
                      {pricePerPerson && (
                        <Text fontSize="sm" color="gray.600">
                          {pricePerPerson}€ par personne
                        </Text>
                      )}
                    </HStack>

                    {ride.lastMessage && (
                      <Text 
                        fontSize="sm" 
                        color="gray.500" 
                        mt={2}
                        noOfLines={1}
                      >
                        {ride.lastMessage}
                      </Text>
                    )}
                  </Box>
                </HStack>
              </MotionBox>
            );
          })
        )}
      </VStack>
    </Box>
  );
};

export default Messages;
