import { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Coordonnées des points d'intérêt
const LOCATIONS = {
  CDG: { lat: 49.0097, lng: 2.5479, name: 'Aéroport Roissy-Charles de Gaulle' },
  ORLY: { lat: 48.7262, lng: 2.3659, name: 'Aéroport d\'Orly' },
  GARE_DU_NORD: { lat: 48.8809, lng: 2.3553, name: 'Gare du Nord' },
  GARE_DE_LYON: { lat: 48.8448, lng: 2.3735, name: 'Gare de Lyon' },
  GARE_MONTPARNASSE: { lat: 48.8419, lng: 2.3221, name: 'Gare Montparnasse' },
  GARE_DE_LEST: { lat: 48.8768, lng: 2.3591, name: 'Gare de l\'Est' },
  // Paris center par défaut
  DEFAULT: { lat: 48.8566, lng: 2.3522, name: 'Paris' }
};

// Créer une icône personnalisée pour les marqueurs
const customIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const RideMap = ({ from, to }) => {
  const mapRef = useRef(null);
  console.log('RideMap - from:', from, 'to:', to);
  console.log('LOCATIONS:', LOCATIONS);
  
  const fromLocation = LOCATIONS[from] || LOCATIONS.DEFAULT;
  const toLocation = LOCATIONS[to] || LOCATIONS.DEFAULT;
  
  console.log('fromLocation:', fromLocation);
  console.log('toLocation:', toLocation);

  // Calculer le centre et le zoom pour afficher les deux points
  const bounds = L.latLngBounds([
    [fromLocation.lat, fromLocation.lng],
    [toLocation.lat, toLocation.lng]
  ]);
  const center = bounds.getCenter();

  useEffect(() => {
    // Forcer la mise à jour des tuiles de la carte
    window.dispatchEvent(new Event('resize'));

    // Ajuster la vue pour montrer les deux points
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds);
    }
  }, [from, to]);

  return (
    <Box h="300px" w="100%" borderRadius="md" overflow="hidden">
      <MapContainer
        center={[center.lat, center.lng]}
        zoom={11}
        style={{ height: '100%', width: '100%' }}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        
        {/* Marqueur de départ */}
        <Marker 
          position={[fromLocation.lat, fromLocation.lng]} 
          icon={customIcon}
        >
          <Popup>Départ : {fromLocation.name}</Popup>
        </Marker>
        
        {/* Marqueur d'arrivée */}
        <Marker 
          position={[toLocation.lat, toLocation.lng]} 
          icon={customIcon}
        >
          <Popup>Arrivée : {toLocation.name}</Popup>
        </Marker>

        {/* Ligne du trajet */}
        <Polyline
          positions={[
            [fromLocation.lat, fromLocation.lng],
            [toLocation.lat, toLocation.lng]
          ]}
          color="blue"
          weight={3}
          opacity={0.7}
        />
      </MapContainer>
    </Box>
  );
};

export default RideMap;
