import { VStack, Button, Text, HStack, IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

const SelectSeats = ({ onNext, onBack }) => {
  const [seats, setSeats] = useState(1);

  const handleSeatsChange = (change) => {
    const newValue = seats + change;
    if (newValue >= 1 && newValue <= 4) {
      setSeats(newValue);
    }
  };

  return (
    <VStack spacing={6} w="100%">
      <Text fontSize="xl" fontWeight="bold">
        Nombre de places disponibles
      </Text>

      <HStack spacing={4}>
        <IconButton
          aria-label="Réduire"
          icon={<FontAwesomeIcon icon="minus" />}
          onClick={() => handleSeatsChange(-1)}
          isDisabled={seats <= 1}
        />

        <HStack spacing={2}>
          <FontAwesomeIcon icon="users" />
          <Text fontSize="2xl" fontWeight="bold">
            {seats}
          </Text>
        </HStack>

        <IconButton
          aria-label="Augmenter"
          icon={<FontAwesomeIcon icon="plus" />}
          onClick={() => handleSeatsChange(1)}
          isDisabled={seats >= 4}
        />
      </HStack>

      <Text color="gray.500">
        Maximum 4 places par trajet
      </Text>

      <Button
        w="100%"
        colorScheme="blue"
        onClick={() => onNext({ seats })}
      >
        Continuer
      </Button>
    </VStack>
  );
};

export default SelectSeats;
