import { useState } from 'react';
import { Box, Stepper, Step, StepIndicator, StepStatus, StepTitle, StepDescription, useSteps, VStack } from '@chakra-ui/react';
import SelectRideType from './steps/SelectRideType';
import SelectStation from './steps/SelectStation';
import SelectDateTime from './steps/SelectDateTime';
import SelectSeats from './steps/SelectSeats';
import RideSummary from './steps/RideSummary';

const steps = [
  { title: 'Type de trajet', description: 'Depuis/vers aéroport' },
  { title: 'Départ', description: 'Point de départ' },
  { title: 'Arrivée', description: 'Point d\'arrivée' },
  { title: 'Date & Heure', description: 'Planification' },
  { title: 'Places', description: 'Nombre de places' },
  { title: 'Résumé', description: 'Confirmation' }
];

const CreateRide = () => {
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [rideData, setRideData] = useState({
    type: '', // 'toAirport' ou 'fromAirport'
    departure: '',
    destination: '',
    date: '',
    time: '',
    seats: 1,
  });

  const handleStepChange = (field, value) => {
    setRideData(prev => ({ ...prev, [field]: value }));
    goToNext();
  };

  const handleDateTimeChange = (dateTime) => {
    setRideData(prev => ({
      ...prev,
      date: dateTime.date,
      time: dateTime.time
    }));
    goToNext();
  };

  const handleSeatsChange = (seatsData) => {
    setRideData(prev => ({
      ...prev,
      seats: seatsData.seats
    }));
    goToNext();
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <SelectRideType onChange={(value) => handleStepChange('type', value)} />;
      case 1:
        return (
          <SelectStation 
            type="departure" 
            rideType={rideData.type}
            onChange={(value) => handleStepChange('departure', value)} 
          />
        );
      case 2:
        return (
          <SelectStation 
            type="destination"
            rideType={rideData.type}
            onChange={(value) => handleStepChange('destination', value)} 
          />
        );
      case 3:
        return (
          <SelectDateTime 
            onNext={handleDateTimeChange} 
            onBack={goToPrevious}
            onDateChange={(date) => setRideData(prev => ({ ...prev, date }))}
            onTimeChange={(time) => setRideData(prev => ({ ...prev, time }))}
            date={rideData.date}
            time={rideData.time}
          />
        );
      case 4:
        return (
          <SelectSeats 
            onNext={handleSeatsChange} 
            onBack={goToPrevious}
          />
        );
      case 5:
        return <RideSummary rideData={rideData} onBack={goToPrevious} />;
      default:
        return null;
    }
  };

  return (
    <Box maxW="600px" mx="auto" p={4} mb="80px">
      <Box position="sticky" top={0} bg="white" zIndex={10} pb={4}>
        <Stepper index={activeStep} orientation="horizontal" mb={8}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<Box w={2} h={2} bg="blue.500" borderRadius="full" />}
                  incomplete={<Box w={2} h={2} bg="gray.300" borderRadius="full" />}
                  active={<Box w={2} h={2} bg="blue.500" borderRadius="full" />}
                />
              </StepIndicator>

              <Box flexShrink="0" display={{ base: 'none', md: 'block' }}>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>
            </Step>
          ))}
        </Stepper>
      </Box>

      <VStack spacing={4} mt={4}>
        {renderStep()}
      </VStack>
    </Box>
  );
};

export default CreateRide;
