import {
  VStack,
  Box,
  Text,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Center,
  Button,
} from '@chakra-ui/react';
import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { useAuth } from '../../contexts/AuthContext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ShareModal from '../common/ShareModal';
import RideCard from './RideCard';
import AuthModal from '../auth/AuthModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const RideList = ({ rides, loading, onSelect, isUserRides = false }) => {
  const toast = useToast();
  const { user } = useAuth();
  const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();
  const { isOpen: isAuthOpen, onOpen: onAuthOpen, onClose: onAuthClose } = useDisclosure();
  const [selectedRide, setSelectedRide] = useState(null);
  const navigate = useNavigate();

  const handleJoinRide = async (ride) => {
    if (!user) {
      onAuthOpen();
      return;
    }

    try {
      // Vérifier si l'utilisateur n'est pas déjà dans le trajet
      const rideRef = doc(db, 'rides', ride.id);
      const rideDoc = await getDoc(rideRef);
      const rideData = rideDoc.data();

      if (rideData.participants?.includes(user.email)) {
        toast({
          title: 'Déjà inscrit',
          description: 'Vous participez déjà à ce trajet',
          status: 'info',
          duration: 5000,
        });
        return;
      }

      // Vérifier s'il reste des places
      if (rideData.participants?.length >= 4) {
        toast({
          title: 'Trajet complet',
          description: 'Il n\'y a plus de places disponibles',
          status: 'error',
          duration: 5000,
        });
        return;
      }

      // Ajouter l'utilisateur aux participants
      await updateDoc(rideRef, {
        participants: arrayUnion(user.email)
      });

      toast({
        title: 'Trajet rejoint !',
        description: 'Vous avez rejoint le trajet avec succès',
        status: 'success',
        duration: 5000,
      });

      // Ouvrir le modal de partage
      setSelectedRide(ride);
      onShareOpen();

      // Mettre à jour l'affichage
      if (onSelect) onSelect(ride);
    } catch (error) {
      console.error('Erreur lors de la participation au trajet:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de rejoindre le trajet',
        status: 'error',
        duration: 5000,
      });
    }
  };

  const handleShare = (ride) => {
    setSelectedRide(ride);
    onShareOpen();
  };

  const handleDelete = async (ride) => {
    // TODO: Implement ride deletion
    console.log('Delete ride:', ride);
  };

  const handleMessage = (ride) => {
    if (!user) {
      toast({
        title: 'Connexion requise',
        description: 'Vous devez être connecté pour envoyer un message',
        status: 'warning',
        duration: 5000,
      });
      return;
    }
    navigate(`/messages/${ride.id}`);
  };

  if (loading) {
    return (
      <Center py={8}>
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Center>
    );
  }

  if (!rides || rides.length === 0) {
    return (
      <Box textAlign="center" py={8}>
        <Text fontSize="lg" color="gray.500" mb={user ? 4 : 0}>
          Aucun trajet disponible pour le moment
        </Text>
        {user && (
          <Button
            colorScheme="blue"
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => navigate('/creer-trajet')}
            size="lg"
            mt={4}
          >
            Créer un trajet
          </Button>
        )}
      </Box>
    );
  }

  return (
    <>
      <VStack 
        spacing={6}
        w="full"
        maxW="600px"
        mx="auto"
        px={4}
      >
        {rides.map((ride) => (
          <RideCard
            key={ride.id}
            ride={ride}
            onJoin={() => handleJoinRide(ride)}
            onShare={() => handleShare(ride)}
            onDelete={isUserRides ? () => handleDelete(ride) : undefined}
            onMessage={() => handleMessage(ride)}
            isUserRide={isUserRides}
            currentUser={user}
          />
        ))}
      </VStack>

      <ShareModal
        isOpen={isShareOpen}
        onClose={onShareClose}
        ride={selectedRide}
      />

      <AuthModal
        isOpen={isAuthOpen}
        onClose={onAuthClose}
      />
    </>
  );
};

export default RideList;
