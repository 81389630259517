import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: {
      main: '#007AFF',
    },
    secondary: {
      main: '#FFFFFF',
    },
    tertiary: {
      main: '#F2F2F2',
    },
    text: {
      primary: '#333333',
      secondary: '#555555',
    },
  },
  fonts: {
    heading: 'Inter, Roboto, sans-serif',
    body: 'Inter, Roboto, sans-serif',
  },
  styles: {
    global: {
      body: {
        bg: 'white',
        color: 'text.primary',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: 'md',
      },
      variants: {
        primary: {
          bg: 'primary.main',
          color: 'white',
          _hover: {
            bg: 'primary.main',
            opacity: 0.9,
          },
        },
      },
    },
  },
});

export default theme;
