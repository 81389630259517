import { 
  VStack, 
  Button, 
  Text, 
  Box, 
  Divider, 
  useToast, 
  Heading, 
  useDisclosure,
  Badge,
  HStack,
  Wrap,
  WrapItem,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useColorModeValue,
  Stack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import RideMap from '../../map/RideMap';
import ShareModal from '../../common/ShareModal';
import { calculatePricePerPerson } from '../../../config/prices';
import { useAuth } from '../../../contexts/AuthContext';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const MAX_SEATS = 4;

const formatStation = (stationId) => {
  const stations = {
    CDG: 'Roissy Charles de Gaulle',
    ORLY: 'Orly',
    GARE_DU_NORD: 'Gare du Nord',
    GARE_DE_LYON: 'Gare de Lyon',
    GARE_MONTPARNASSE: 'Gare Montparnasse',
    GARE_DE_LEST: "Gare de l'Est"
  };
  
  if (stationId.startsWith('PARIS_')) {
    return `Paris ${stationId.replace('PARIS_', '')}`;
  }
  
  return stations[stationId] || stationId;
};

const RideSummary = ({ rideData, onBack }) => {
  const toast = useToast();
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const accentColor = useColorModeValue('blue.500', 'blue.300');

  // Calculer le prix par personne
  console.log('Debug RideSummary - rideData:', {
    type: rideData.type,
    departure: rideData.departure,
    destination: rideData.destination,
    seats: rideData.seats
  });

  const pricePerPerson = rideData.type === 'toAirport' 
    ? calculatePricePerPerson(
        rideData.destination,  // l'aéroport
        rideData.departure,    // la gare/district
        1
      )
    : calculatePricePerPerson(
        rideData.departure,    // l'aéroport
        rideData.destination,  // la gare/district
        1
      );

  const remainingSeats = rideData.seats;
  const occupancyRate = (remainingSeats / MAX_SEATS) * 100;

  const formatDate = (date, time) => {
    const [year, month, day] = date.split('-');
    const [hours, minutes] = time.split(':');
    const dateObj = new Date(year, month - 1, day, hours, minutes);
    return format(dateObj, "EEEE d MMMM yyyy 'à' HH 'h' mm", { locale: fr });
  };

  const handleShare = async () => {
    try {
      // On sauvegarde d'abord le trajet
      if (!user) {
        throw new Error('Utilisateur non connecté');
      }

      const [year, month, day] = rideData.date.split('-');
      const [hours, minutes] = rideData.time.split(':');
      
      const dateTime = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day),
        parseInt(hours),
        parseInt(minutes)
      );

      if (isNaN(dateTime.getTime())) {
        throw new Error('Date invalide');
      }

      const rideToSave = {
        departure: rideData.departure,
        destination: rideData.destination,
        date: dateTime.toISOString(),
        time: rideData.time,
        seats: rideData.seats,
        type: rideData.type,
        pricePerSeat: pricePerPerson,
        estimatedTaxiPrice: pricePerPerson * 3, // Estimation du prix d'un taxi classique
        availableSeats: rideData.seats,
        createdBy: user.email,
        creatorName: user.displayName || 'Utilisateur',
        creatorPhotoURL: user.photoURL || '',
        createdAt: new Date().toISOString(),
        participants: [user.email],
        status: 'active'
      };

      const docRef = await addDoc(collection(db, 'rides'), rideToSave);
      
      toast({
        title: 'Trajet créé avec succès !',
        description: "Votre trajet a été publié. Partagez-le maintenant !",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // On ouvre la modale de partage avec les données du trajet
      onOpen();

    } catch (error) {
      console.error('Erreur lors de la création du trajet:', error);
      toast({
        title: 'Erreur',
        description: error.message || "Une erreur s'est produite lors de la création du trajet.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction appelée quand la modale est fermée
  const handleModalClose = () => {
    onClose();
    // On redirige seulement après la fermeture de la modale
    window.location.href = '/';
  };

  const basePrice = pricePerPerson;

  return (
    <VStack spacing={6} align="stretch" bg={bgColor} p={6} borderRadius="lg" borderWidth="1px" borderColor={borderColor}>
      {/* En-tête avec badges */}
      <Wrap spacing={4}>
        <WrapItem>
          <Badge colorScheme="blue">À venir</Badge>
        </WrapItem>
        <WrapItem>
          <Badge colorScheme="green">
            {remainingSeats} place{remainingSeats > 1 ? 's' : ''} disponible{remainingSeats > 1 ? 's' : ''}
          </Badge>
        </WrapItem>
        <WrapItem>
          <Badge colorScheme="purple">
            {pricePerPerson}€ → {Math.ceil(pricePerPerson / 2)}€ si partagé
          </Badge>
        </WrapItem>
      </Wrap>

      {/* Carte */}
      <Box h="300px">
        <RideMap from={rideData.departure} to={rideData.destination} />
      </Box>

      {/* Informations du trajet */}
      <VStack spacing={4} align="stretch">
        <Box>
          <HStack spacing={4}>
            <FontAwesomeIcon icon="location-dot" color={accentColor} />
            <Text fontWeight="bold">{formatStation(rideData.departure)}</Text>
          </HStack>
          <HStack spacing={4}>
            <FontAwesomeIcon icon="location-dot" color={accentColor} />
            <Text fontWeight="bold">{formatStation(rideData.destination)}</Text>
          </HStack>
        </Box>
      </VStack>

      {/* Date et places */}
      <VStack spacing={4}>
        <HStack>
          <FontAwesomeIcon icon="calendar" />
          <Text>{formatDate(rideData.date, rideData.time)}</Text>
        </HStack>
        <HStack>
          <FontAwesomeIcon icon="users" />
          <Text>{rideData.seats} place{rideData.seats > 1 ? 's' : ''}</Text>
        </HStack>
      </VStack>

      <Divider />

      {/* Statistiques */}
      <Stack spacing={4}>
        <Stat>
          <StatLabel>Prix actuel</StatLabel>
          <StatNumber>{basePrice}€</StatNumber>
          <StatHelpText>
            {Math.ceil(basePrice / 2)}€ si quelqu'un rejoint
          </StatHelpText>
        </Stat>
        
        <Stat>
          <StatLabel>Économies potentielles</StatLabel>
          <StatNumber>{Math.ceil(basePrice / 2)}€</StatNumber>
          <StatHelpText>
            50% d'économie si quelqu'un rejoint
          </StatHelpText>
        </Stat>
      </Stack>

      {/* Boutons d'action */}
      <HStack spacing={4} justify="flex-end">
        <Button 
          leftIcon={<FontAwesomeIcon icon="arrow-left" />} 
          onClick={onBack}
          variant="ghost"
        >
          Retour
        </Button>
        <Button
          leftIcon={<FontAwesomeIcon icon="share" />}
          colorScheme="blue"
          onClick={handleShare}
        >
          Partager et créer
        </Button>
      </HStack>

      <ShareModal 
        isOpen={isOpen} 
        onClose={handleModalClose} 
        ride={rideData} 
      />
    </VStack>
  );
};

export default RideSummary;
