import { 
  Box, 
  VStack, 
  Heading, 
  Text, 
  Button, 
  Container, 
  SimpleGrid, 
  Icon,
  useColorModeValue,
  Flex,
  Stack,
  Circle,
  HStack,
  Image,
  Divider,
  useBreakpointValue,
  Badge,
  Center
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTaxi, 
  faUsers, 
  faMoneyBillWave,
  faLeaf,
  faHandshake,
  faPercent,
  faAward,
  faHeart
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../contexts/AuthContext';
import { motion, useViewportScroll, useTransform } from 'framer-motion';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionImage = motion(Image);

const Feature = ({ title, text, icon, isMain }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  const iconBg = useColorModeValue('blue.50', 'blue.900');
  const iconColor = useColorModeValue('blue.600', 'blue.200');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <MotionBox
      whileHover={{ scale: 1.05 }}
      p={6}
      bg={cardBg}
      rounded="xl"
      borderWidth="1px"
      borderColor={borderColor}
      shadow="xl"
      position="relative"
      overflow="hidden"
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bg: 'linear-gradient(45deg, transparent 50%, rgba(66, 153, 225, 0.03) 100%)',
        zIndex: 0
      }}
    >
      {isMain && (
        <Badge
          position="absolute"
          top={2}
          right={2}
          colorScheme="green"
          variant="solid"
          rounded="full"
          px={3}
        >
          Gratuit
        </Badge>
      )}
      <Flex direction="column" position="relative" zIndex={1}>
        <Circle size={12} bg={iconBg} color={iconColor} mb={4}>
          <FontAwesomeIcon icon={icon} size="lg" />
        </Circle>
        <Text
          fontSize="xl"
          fontWeight="bold"
          mb={2}
          bgGradient="linear(to-r, blue.400, blue.600)"
          bgClip="text"
        >
          {title}
        </Text>
        <Text color="gray.500" fontSize="md">
          {text}
        </Text>
      </Flex>
    </MotionBox>
  );
};

const ProcessStep = ({ number, title, description, icon }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const numberColor = useColorModeValue('blue.500', 'blue.300');
  
  return (
    <MotionBox
      whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
      p={6}
      bg={bgColor}
      rounded="xl"
      shadow="lg"
      position="relative"
      overflow="hidden"
    >
      <Flex direction="column" align="center" textAlign="center">
        <Circle
          size={12}
          bg="blue.50"
          color="blue.500"
          mb={4}
          position="relative"
        >
          <FontAwesomeIcon icon={icon} size="lg" />
          <Circle
            size={5}
            bg={numberColor}
            color="white"
            position="absolute"
            top={-2}
            right={-2}
            fontSize="xs"
            fontWeight="bold"
          >
            {number}
          </Circle>
        </Circle>
        <Text
          fontSize="xl"
          fontWeight="bold"
          mb={2}
          bgGradient="linear(to-r, blue.400, blue.600)"
          bgClip="text"
        >
          {title}
        </Text>
        <Text color="gray.500" fontSize="md">
          {description}
        </Text>
      </Flex>
    </MotionBox>
  );
};

const Statistic = ({ number, label }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const labelColor = useColorModeValue('gray.600', 'gray.300');
  
  return (
    <MotionBox
      initial={{ opacity: 0, scale: 0.9 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <Box 
        textAlign="center" 
        bg={cardBg}
        p={8}
        rounded="2xl"
        shadow="lg"
        borderWidth="1px"
        borderColor={borderColor}
        position="relative"
        overflow="hidden"
        _after={{
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '4px',
          bgGradient: 'linear(to-r, blue.400, blue.600)'
        }}
      >
        <Text 
          fontSize={{ base: "4xl", md: "5xl" }} 
          fontWeight="bold" 
          bgGradient="linear(to-r, blue.400, blue.600)" 
          bgClip="text"
          mb={2}
        >
          {number}
        </Text>
        <Text 
          color={labelColor} 
          fontSize={{ base: "md", md: "lg" }}
          fontWeight="medium"
        >
          {label}
        </Text>
      </Box>
    </MotionBox>
  );
};

const Home = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 300], [0, 50]);
  const y2 = useTransform(scrollY, [0, 300], [0, -50]);
  
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const headingColor = useColorModeValue('gray.800', 'white');

  return (
    <Box bg={bgColor} minH="100vh">
      {/* Hero Section */}
      <Container maxW="container.xl" pt={{ base: 20, md: 28 }} pb={20}>
        <VStack spacing={8} align="center" w="full">
          {/* Logo */}
          <MotionBox
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Image
              src="/CoTaxiageLOGODEF.svg"
              alt="CoTaxiage Logo"
              w={{ base: "200px", md: "250px" }}
              h="auto"
            />
          </MotionBox>

          {/* Boutons */}
          {user ? (
            <HStack 
              spacing={4} 
              w={{ base: "full", md: "auto" }}
              flexWrap={{ base: "wrap", md: "nowrap" }}
              justify="center"
            >
              <Button
                size="lg"
                colorScheme="blue"
                leftIcon={<FontAwesomeIcon icon="plus" />}
                onClick={() => navigate('/creer-trajet')}
                w={{ base: "full", md: "auto" }}
                py={7}
                px={8}
                fontSize="lg"
                fontWeight="bold"
                _hover={{
                  transform: 'translateY(-2px)',
                  shadow: 'lg',
                }}
              >
                Créer un trajet
              </Button>
              
              <Button
                size="lg"
                variant="ghost"
                leftIcon={<FontAwesomeIcon icon="search" />}
                onClick={() => navigate('/rechercher')}
                w={{ base: "full", md: "auto" }}
                py={7}
                px={8}
                fontSize="lg"
                fontWeight="bold"
                _hover={{
                  transform: 'translateY(-2px)',
                  bg: 'gray.100',
                }}
              >
                Rechercher
              </Button>
            </HStack>
          ) : (
            <VStack spacing={6} align="center" w="full">
              <Button
                size="lg"
                colorScheme="blue"
                leftIcon={<FontAwesomeIcon icon="search" />}
                onClick={() => navigate('/rechercher')}
                w={{ base: "full", md: "auto" }}
                py={7}
                px={8}
                fontSize="lg"
                fontWeight="bold"
                _hover={{
                  transform: 'translateY(-2px)',
                  shadow: 'lg',
                }}
              >
                Commencer maintenant
              </Button>
              
              <Text 
                color={textColor} 
                fontSize="md"
                fontWeight="medium"
              >
                Rejoignez notre communauté de Co-Taxieurs responsables
              </Text>
            </VStack>
          )}
        </VStack>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 8, md: 10 }}
          align="center"
          justify="space-between"
          mt={12}
        >
          <VStack
            spacing={6}
            align="flex-start"
            maxW={{ base: 'full', md: '2xl' }}
          >
            <Badge
              colorScheme="green"
              fontSize="md"
              px={4}
              py={1}
              rounded="full"
              mb={2}
            >
              100% Gratuit
            </Badge>
            <Heading
              as="h1"
              size="2xl"
              fontWeight="bold"
              lineHeight="shorter"
              bgGradient="linear(to-r, blue.400, blue.600)"
              bgClip="text"
            >
              Partagez vos taxis vers les gares et aéroports
            </Heading>
            <Text fontSize="xl" color={textColor}>
              Service d'utilité publique pour tous. Économisez jusqu'à 75% sur vos trajets en taxi.
            </Text>
          </VStack>

          <MotionBox
            style={{ y: y1 }}
            flex={1}
            maxW={{ base: 'full', md: '500px' }}
            display={{ base: 'none', md: 'block' }}
          >
            {/* Ici vous pouvez ajouter l'illustration du taxi */}
          </MotionBox>
        </Stack>
      </Container>

      {/* Section Avantages */}
      <Box bg={useColorModeValue('white', 'gray.800')} py={20}>
        <Container maxW="container.xl">
          <VStack spacing={12}>
            <VStack spacing={4} textAlign="center" maxW="2xl">
              <Heading size="xl" color={headingColor}>
                Pourquoi choisir CoTaxiage ?
              </Heading>
              <Text fontSize="lg" color={textColor}>
                Une solution économique et écologique pour vos déplacements
              </Text>
            </VStack>

            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              spacing={10}
              w="full"
            >
              <Feature
                icon={faMoneyBillWave}
                title="Économies Maximales"
                text="Jusqu'à 75% d'économies sur vos trajets en taxi"
                isMain={true}
              />
              <Feature
                icon={faPercent}
                title="Premier Partage"
                text="-50% dès votre premier partage de trajet"
              />
              <Feature
                icon={faAward}
                title="un Service d'utilité Public"
                text="CoTaxiage est accessible à tous, sans frais ni commission"
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>

      {/* Section Comment ça marche */}
      <Box py={20}>
        <Container maxW="container.xl">
          <VStack spacing={12}>
            <VStack spacing={4} textAlign="center" maxW="2xl">
              <Heading size="xl" color={headingColor}>
                Comment ça marche ?
              </Heading>
              <Text fontSize="lg" color={textColor}>
                En 3 étapes simples, commencez à partager vos trajets
              </Text>
            </VStack>

            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              spacing={10}
              w="full"
            >
              <ProcessStep
                number="1"
                icon={faTaxi}
                title="Choisissez votre destination"
                description="Sélectionnez votre gare ou aéroport de départ/arrivée"
              />
              <ProcessStep
                number="2"
                icon={faUsers}
                title="Trouvez des Co-Taxieurs"
                description="Connectez-vous avec d'autres Co-Taxieurs sur le même trajet"
              />
              <ProcessStep
                number="3"
                icon={faHandshake}
                title="Partagez les frais"
                description="Divisez équitablement le coût du trajet"
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>

      {/* Section Points Forts */}
      <Box bg={useColorModeValue('white', 'gray.800')} py={20}>
        <Container maxW="container.xl">
          <VStack spacing={12}>
            <VStack spacing={4} textAlign="center" maxW="2xl">
              <Heading size="xl" color={headingColor}>
                Nos engagements
              </Heading>
              <Text fontSize="lg" color={textColor}>
                Une plateforme transparente et accessible à tous
              </Text>
            </VStack>

            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              spacing={10}
              w="full"
            >
              <Feature
                icon={faHeart}
                title="100% Gratuit"
                text="Aucune commission, aucuns frais cachés"
              />
              <Feature
                icon={faLeaf}
                title="Écologique"
                text="Réduisez votre empreinte carbone en partageant vos trajets"
              />
              <Feature
                icon={faHandshake}
                title="Pour Tous"
                text="Accessible à tous sans restriction"
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
