const CLOUDINARY_UPLOAD_PRESET = 'cotaxiage_preset'; 
const CLOUDINARY_CLOUD_NAME = 'dczl5zcis';

// Fonction utilitaire pour construire les URLs Cloudinary
export const buildImageUrl = (url, options = {}) => {
  if (!url || !url.includes('cloudinary')) return url;

  // Extraire le chemin de base
  const basePath = url.split('/upload/')[1];

  // Construire les transformations
  const transformations = [
    'f_auto', // Format automatique
    'q_auto', // Qualité automatique
    options.width ? `w_${options.width}` : '',
    options.height ? `h_${options.height}` : '',
    'c_fill', // Mode de recadrage
    'g_face', // Gravité sur le visage pour les photos de profil
  ].filter(Boolean).join(',');

  return `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/${transformations}/${basePath}`;
};

export const uploadImageToCloudinary = async (file, progressCallback) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    
    formData.append('file', file);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
    
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const progress = Math.round((e.loaded * 100) / e.total);
        if (progressCallback) progressCallback(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        resolve(response.secure_url);
      } else {
        console.error('Erreur Cloudinary:', xhr.responseText);
        reject(new Error('Erreur lors du téléchargement de l\'image'));
      }
    };

    xhr.onerror = () => {
      reject(new Error('Erreur réseau lors du téléchargement'));
    };

    xhr.open('POST', `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`);
    xhr.send(formData);
  });
};

export const deleteImageFromCloudinary = async (publicId) => {
  // Note: La suppression d'images nécessite une signature côté serveur pour des raisons de sécurité
  return true;
};
