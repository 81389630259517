export const formatStation = (stationId) => {
  const stations = {
    CDG: 'Roissy Charles de Gaulle',
    ORLY: 'Orly',
    GARE_DU_NORD: 'Gare du Nord',
    GARE_DE_LYON: 'Gare de Lyon',
    GARE_MONTPARNASSE: 'Gare Montparnasse',
    GARE_DE_LEST: "Gare de l'Est"
  };
  return stations[stationId] || stationId;
};

export const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.error('Date invalide:', dateString);
      return 'Date invalide';
    }

    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };

    // Formater la date en français
    const formatter = new Intl.DateTimeFormat('fr-FR', options);
    return formatter.format(date)
      .replace(':', ' h ') // Remplacer : par h pour le format français
      .replace(/^./, str => str.toUpperCase()); // Première lettre en majuscule
  } catch (error) {
    console.error('Erreur lors du formatage de la date:', error);
    return 'Date invalide';
  }
};
