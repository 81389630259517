import { useState } from 'react';
import {
  VStack,
  HStack,
  Box,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { updateProfile } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import ProfilePhotoUpload from './ProfilePhotoUpload';

const PersonalInfo = ({ user }) => {
  const toast = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    displayName: user.displayName || '',
    phoneNumber: user.phoneNumber || '',
    email: user.email || '',
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setFormData({
      displayName: user.displayName || '',
      phoneNumber: user.phoneNumber || '',
      email: user.email || '',
    });
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      // Mettre à jour le profil Firebase Auth
      await updateProfile(user, {
        displayName: formData.displayName,
      });

      // Mettre à jour les données supplémentaires dans Firestore
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, {
        displayName: formData.displayName,
        phoneNumber: formData.phoneNumber,
        updatedAt: new Date().toISOString(),
      });

      setIsEditing(false);
      toast({
        title: 'Profil mis à jour',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de mettre à jour le profil',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={6} align="stretch">
      <Box display="flex" justifyContent="center" mb={4}>
        <ProfilePhotoUpload />
      </Box>

      <Box>
        <FormControl>
          <FormLabel>Nom complet</FormLabel>
          <Input
            value={formData.displayName}
            onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
            isReadOnly={!isEditing}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Email</FormLabel>
          <Input
            value={formData.email}
            isReadOnly
            type="email"
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Téléphone</FormLabel>
          <Input
            value={formData.phoneNumber}
            onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
            isReadOnly={!isEditing}
            type="tel"
          />
        </FormControl>

        {isEditing ? (
          <HStack spacing={4} mt={6} justify="flex-end">
            <Button
              leftIcon={<FontAwesomeIcon icon={faTimes} />}
              onClick={handleCancel}
              variant="outline"
            >
              Annuler
            </Button>
            <Button
              leftIcon={<FontAwesomeIcon icon={faSave} />}
              onClick={handleSave}
              colorScheme="blue"
            >
              Enregistrer
            </Button>
          </HStack>
        ) : (
          <Button
            leftIcon={<FontAwesomeIcon icon={faEdit} />}
            onClick={handleEdit}
            mt={6}
          >
            Modifier
          </Button>
        )}
      </Box>
    </VStack>
  );
};

export default PersonalInfo;
