import {
  VStack,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TermsOfService from '../../legal/TermsOfService';
import PrivacyPolicy from '../../legal/PrivacyPolicy';

const Support = () => {
  const { 
    isOpen: isTermsOpen, 
    onOpen: onTermsOpen, 
    onClose: onTermsClose 
  } = useDisclosure();
  
  const { 
    isOpen: isPrivacyOpen, 
    onOpen: onPrivacyOpen, 
    onClose: onPrivacyClose 
  } = useDisclosure();

  const faqItems = [
    {
      question: "Comment créer un trajet ?",
      answer: "Pour créer un trajet, cliquez sur le bouton '+' dans la barre de navigation. Suivez ensuite les étapes pour définir le type de trajet, le point de départ, d'arrivée, la date et le nombre de places disponibles."
    },
    {
      question: "Comment réserver un trajet ?",
      answer: "Utilisez la fonction de recherche pour trouver un trajet qui vous convient. Une fois trouvé, cliquez sur le trajet pour voir les détails et effectuer la réservation."
    },
    {
      question: "Comment modifier mes informations personnelles ?",
      answer: "Dans votre profil, allez dans l'onglet 'Informations' et cliquez sur 'Modifier'. Vous pourrez alors mettre à jour vos informations."
    },
    {
      question: "Comment supprimer mon compte ?",
      answer: "Dans votre profil, allez dans l'onglet 'Sécurité' et utilisez l'option 'Supprimer mon compte'. Attention, cette action est irréversible."
    }
  ];

  return (
    <VStack spacing={6} align="stretch">
      <Box>
        <Text fontSize="xl" mb={4}>Foire aux questions</Text>
        <Accordion allowMultiple>
          {faqItems.map((item, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <FontAwesomeIcon icon="question-circle" /> {item.question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {item.answer}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>

      <Box>
        <Text fontSize="xl" mb={4}>Nous contacter</Text>
        <Text mb={4}>
          Si vous ne trouvez pas la réponse à votre question, n'hésitez pas à nous contacter :
        </Text>
        <VStack spacing={4} align="stretch">
          <Link href="mailto:support@cotaxiage.fr" isExternal>
            <Button
              leftIcon={<FontAwesomeIcon icon="envelope" />}
              colorScheme="blue"
              variant="outline"
              width="100%"
            >
              support@cotaxiage.fr
            </Button>
          </Link>
        </VStack>
      </Box>

      <Box mt={8}>
        <Text fontSize="xl" mb={4}>Documents légaux</Text>
        <VStack spacing={4} align="stretch">
          <Button
            leftIcon={<FontAwesomeIcon icon="file-alt" />}
            variant="ghost"
            width="100%"
            onClick={onTermsOpen}
          >
            Conditions générales d'utilisation
          </Button>
          <Button
            leftIcon={<FontAwesomeIcon icon="lock" />}
            variant="ghost"
            width="100%"
            onClick={onPrivacyOpen}
          >
            Politique de confidentialité
          </Button>
        </VStack>
      </Box>

      {/* Modal pour les CGU */}
      <Modal isOpen={isTermsOpen} onClose={onTermsClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Conditions Générales d'Utilisation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TermsOfService />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onTermsClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal pour la politique de confidentialité */}
      <Modal isOpen={isPrivacyOpen} onClose={onPrivacyClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Politique de Confidentialité</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PrivacyPolicy />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onPrivacyClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Support;
