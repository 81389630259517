import { useState, useEffect } from 'react';
import { 
  VStack, 
  Button, 
  Text, 
  Input, 
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorModeValue,
  HStack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectDateTime = ({ onNext, onBack, onDateChange, onTimeChange, date: initialDate, time: initialTime }) => {
  const [date, setDate] = useState(initialDate || '');
  const [time, setTime] = useState(initialTime || '');
  const [error, setError] = useState('');
  const [minDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });

  // Couleurs dynamiques pour le mode sombre/clair
  const inputBg = useColorModeValue('white', 'gray.800');
  const inputBorder = useColorModeValue('gray.200', 'gray.600');
  const inputHover = useColorModeValue('gray.300', 'gray.500');

  useEffect(() => {
    // Réinitialiser l'erreur quand la date ou l'heure change
    if (date && time) {
      setError('');
    }
  }, [date, time]);

  // Mettre à jour l'état local quand les props changent
  useEffect(() => {
    if (initialDate !== undefined) {
      setDate(initialDate);
    }
  }, [initialDate]);

  useEffect(() => {
    if (initialTime !== undefined) {
      setTime(initialTime);
    }
  }, [initialTime]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setDate(newDate);
    onDateChange(newDate);
    setTime('');
    onTimeChange('');
    setError('');
  };

  const handleTimeChange = (e) => {
    const newTime = e.target.value;
    setTime(newTime);
    onTimeChange(newTime);
  };

  const handleNext = () => {
    if (!date || !time) {
      setError('Veuillez sélectionner une date et une heure');
      return;
    }

    if (!isValidDateTime(date, time)) {
      setError('Pour aujourd\'hui, veuillez sélectionner une heure avec au moins 30 minutes d\'avance');
      return;
    }

    onNext({ date, time });
  };

  const isValidDateTime = (selectedDate, selectedTime) => {
    if (!selectedDate || !selectedTime) return false;

    const [hours, minutes] = selectedTime.split(':');
    const selectedDateTime = new Date(selectedDate);
    selectedDateTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);

    const now = new Date();
    const today = now.toISOString().split('T')[0];

    console.log('Debug isValidDateTime:');
    console.log('Selected Date:', selectedDate);
    console.log('Today:', today);
    console.log('Selected Time:', selectedTime);
    console.log('Selected DateTime:', selectedDateTime);
    console.log('Now:', now);
    console.log('Is Future Date:', selectedDate > today);
    console.log('Is Future Time:', selectedDateTime > now);

    // Pour les jours futurs, toujours valide
    if (selectedDate > today) {
      return true;
    }

    // Pour aujourd'hui, vérifier l'heure
    return selectedDateTime > now;
  };

  const getAvailableTimeSlots = () => {
    const slots = [];
    const now = new Date();
    const isToday = date === now.toISOString().split('T')[0];
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();

    // Commencer à 3h00 et finir à 23h30
    for (let hour = 3; hour <= 23; hour++) {
      // Pour aujourd'hui, ne montrer que les heures futures avec 30 minutes d'avance
      if (isToday && hour <= currentHour) continue;

      for (let minute = 0; minute < 60; minute += 30) {
        // Pour l'heure actuelle, vérifier les 30 minutes d'avance
        if (isToday && hour === currentHour && minute <= currentMinutes + 30) continue;

        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        slots.push(`${formattedHour}:${formattedMinute}`);
      }
    }

    return slots;
  };

  return (
    <VStack spacing={6} align="stretch" w="100%">
      <FormControl isInvalid={!!error}>
        <FormLabel>Date</FormLabel>
        <Input
          type="date"
          value={date}
          min={minDate}
          onChange={handleDateChange}
          bg={inputBg}
          borderColor={inputBorder}
          _hover={{ borderColor: inputHover }}
        />
      </FormControl>

      <FormControl isInvalid={!!error}>
        <FormLabel>Heure</FormLabel>
        <Select
          value={time}
          onChange={handleTimeChange}
          placeholder="Sélectionnez une heure"
          isDisabled={!date}
          bg={inputBg}
          borderColor={inputBorder}
          _hover={{ borderColor: inputHover }}
        >
          {getAvailableTimeSlots().map((slot) => (
            <option key={slot} value={slot}>
              {slot}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>

      <HStack spacing={4} justify="center" mt={4}>
        <Button
          variant="ghost"
          onClick={onBack}
          leftIcon={<FontAwesomeIcon icon="arrow-left" />}
        >
          Retour
        </Button>
        <Button
          colorScheme="blue"
          onClick={handleNext}
          rightIcon={<FontAwesomeIcon icon="arrow-right" />}
          isDisabled={!date || !time}
        >
          Continuer
        </Button>
      </HStack>
    </VStack>
  );
};

export default SelectDateTime;
