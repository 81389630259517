import { Box, Flex, IconButton, Badge } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase.config';
import { getUserRides, getUnreadMessageCount } from '../services/chat.service';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useAuthState(auth);
  const [unreadTotal, setUnreadTotal] = useState(0);

  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    const loadUnreadCount = async () => {
      if (user) {
        try {
          const rides = await getUserRides(user.email);
          const counts = await Promise.all(
            rides.map(ride => getUnreadMessageCount(ride.id, user.email))
          );
          setUnreadTotal(counts.reduce((a, b) => a + b, 0));
        } catch (error) {
          console.error('Error loading unread counts:', error);
        }
      }
    };

    loadUnreadCount();
    const interval = setInterval(loadUnreadCount, 30000);
    return () => clearInterval(interval);
  }, [user]);

  return (
    <Box 
      position="fixed" 
      bottom={0} 
      left={0} 
      right={0} 
      bg="white" 
      boxShadow="0 -2px 10px rgba(0,0,0,0.1)"
      zIndex={1000}
    >
      <Flex justify="space-around" p={4}>
        {/* Toujours visible */}
        <IconButton
          aria-label="Accueil"
          icon={<FontAwesomeIcon icon="home" />}
          onClick={() => navigate('/')}
          variant={isActive('/') ? "solid" : "ghost"}
          colorScheme={isActive('/') ? "blue" : "gray"}
        />
        
        {/* Visible uniquement si connecté */}
        {user && (
          <>
            <IconButton
              aria-label="Créer un trajet"
              icon={<FontAwesomeIcon icon="plus" />}
              onClick={() => navigate('/creer-trajet')}
              variant={isActive('/creer-trajet') ? "solid" : "ghost"}
              colorScheme={isActive('/creer-trajet') ? "blue" : "gray"}
            />
            <Box position="relative">
              <IconButton
                aria-label="Messages"
                icon={<FontAwesomeIcon icon="comment" />}
                onClick={() => navigate('/messages')}
                variant={isActive('/messages') ? "solid" : "ghost"}
                colorScheme={isActive('/messages') ? "blue" : "gray"}
              />
              {unreadTotal > 0 && (
                <Box
                  position="absolute"
                  top="-2px"
                  right="-2px"
                  bg="red.500"
                  color="white"
                  borderRadius="full"
                  w="16px"
                  h="16px"
                  fontSize="10px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="bold"
                >
                  {unreadTotal}
                </Box>
              )}
            </Box>
          </>
        )}

        {/* Toujours visible */}
        <IconButton
          aria-label="Rechercher"
          icon={<FontAwesomeIcon icon="search" />}
          onClick={() => navigate('/rechercher')}
          variant={isActive('/rechercher') ? "solid" : "ghost"}
          colorScheme={isActive('/rechercher') ? "blue" : "gray"}
        />

        {/* Afficher soit Login soit Profile */}
        {user ? (
          <IconButton
            aria-label="Profil"
            icon={<FontAwesomeIcon icon="user" />}
            onClick={() => navigate('/profile')}
            variant={isActive('/profile') ? "solid" : "ghost"}
            colorScheme={isActive('/profile') ? "blue" : "gray"}
          />
        ) : (
          <IconButton
            aria-label="Connexion"
            icon={<FontAwesomeIcon icon="sign-in-alt" />}
            onClick={() => navigate('/auth')}
            variant={isActive('/auth') ? "solid" : "ghost"}
            colorScheme={isActive('/auth') ? "blue" : "gray"}
          />
        )}
      </Flex>
    </Box>
  );
};

export default Navigation;
