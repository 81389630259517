import { useState } from 'react';
import {
  VStack,
  Box,
  FormControl,
  FormLabel,
  Switch,
  Select,
  useToast,
  Button,
  Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase.config';

const Preferences = ({ user }) => {
  const toast = useToast();
  const [preferences, setPreferences] = useState({
    emailNotifications: true,
    pushNotifications: true,
    language: 'fr',
  });

  const handleSave = async () => {
    try {
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, {
        preferences: preferences,
        updatedAt: new Date().toISOString(),
      });

      toast({
        title: 'Préférences mises à jour',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour des préférences:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de mettre à jour les préférences',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={6} align="stretch">
      <Box>
        <Text fontSize="xl" mb={4}>Notifications</Text>
        
        <FormControl display="flex" alignItems="center" mb={4}>
          <FormLabel mb="0">
            <FontAwesomeIcon icon="bell" /> Notifications par email
          </FormLabel>
          <Switch
            isChecked={preferences.emailNotifications}
            onChange={(e) => setPreferences({
              ...preferences,
              emailNotifications: e.target.checked
            })}
          />
        </FormControl>

        <FormControl display="flex" alignItems="center" mb={4}>
          <FormLabel mb="0">
            <FontAwesomeIcon icon="bell" /> Notifications push
          </FormLabel>
          <Switch
            isChecked={preferences.pushNotifications}
            onChange={(e) => setPreferences({
              ...preferences,
              pushNotifications: e.target.checked
            })}
          />
        </FormControl>
      </Box>

      <Box>
        <Text fontSize="xl" mb={4}>Langue</Text>
        
        <FormControl mb={4}>
          <Select
            value={preferences.language}
            onChange={(e) => setPreferences({
              ...preferences,
              language: e.target.value
            })}
          >
            <option value="fr">Français</option>
            <option value="en">English</option>
          </Select>
        </FormControl>
      </Box>

      <Button
        colorScheme="blue"
        onClick={handleSave}
        leftIcon={<FontAwesomeIcon icon="save" />}
      >
        Enregistrer les préférences
      </Button>
    </VStack>
  );
};

export default Preferences;
