import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';
import NavigationApps from '../navigation/NavigationApps';

const NavigationModal = ({ isOpen, onClose, ride }) => {
  const handleNavigate = (appName) => {
    // On pourrait ajouter ici une logique supplémentaire si nécessaire
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rejoindre le point de départ</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text mb={4} color="gray.600">
            Choisissez votre application de navigation préférée pour rejoindre le point de rendez-vous.
          </Text>
          
          <NavigationApps
            destination={ride?.departure?.name || "point de départ"}
            address={ride?.departure?.address}
            latitude={ride?.departure?.coordinates?.lat}
            longitude={ride?.departure?.coordinates?.lng}
            onNavigate={handleNavigate}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NavigationModal;
