import { useState } from 'react';
import {
  VStack,
  Button,
  Input,
  Text,
  useToast,
  Divider,
  Box,
  Container,
  Heading,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider
} from 'firebase/auth';
import { auth } from '../../firebase.config';
import { useNavigate } from 'react-router-dom';

const AuthForm = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate();
  
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isLogin) {
        await signInWithEmailAndPassword(auth, email, password);
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
      }
      toast({
        title: isLogin ? 'Connexion réussie' : 'Compte créé avec succès',
        status: 'success',
        duration: 3000,
      });
      navigate('/');
    } catch (error) {
      toast({
        title: 'Erreur',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      const result = await signInWithPopup(auth, provider);
      
      // Vérifier si c'est un nouvel utilisateur
      const isNewUser = result._tokenResponse?.isNewUser;
      
      toast({
        title: isNewUser ? 'Compte créé avec succès' : 'Connexion réussie',
        description: `Bienvenue ${result.user.displayName || 'sur CoTaxiage'}!`,
        status: 'success',
        duration: 3000,
      });
      navigate('/');
    } catch (error) {
      console.error('Erreur Google Sign In:', error);
      let errorMessage = 'Une erreur est survenue lors de la connexion avec Google';
      
      // Messages d'erreur personnalisés
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          errorMessage = 'Vous avez fermé la fenêtre de connexion Google';
          break;
        case 'auth/popup-blocked':
          errorMessage = 'Le popup a été bloqué par votre navigateur. Veuillez autoriser les popups pour ce site.';
          break;
        case 'auth/cancelled-popup-request':
          errorMessage = 'Opération annulée';
          break;
        case 'auth/account-exists-with-different-credential':
          errorMessage = 'Un compte existe déjà avec cette adresse email mais avec une méthode de connexion différente';
          break;
        default:
          errorMessage = error.message;
      }
      
      toast({
        title: 'Erreur de connexion',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="md" py={12}>
      <Box
        bg={bgColor}
        p={8}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        boxShadow="lg"
      >
        <VStack spacing={6} as="form" onSubmit={handleSubmit}>
          <Heading size="lg" textAlign="center" color="blue.500">
            {isLogin ? 'Connexion' : 'Inscription'}
          </Heading>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FontAwesomeIcon} icon="envelope" color="gray.300" />
            </InputLeftElement>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              size="lg"
              borderRadius="md"
              _focus={{
                borderColor: "blue.500",
                boxShadow: "0 0 0 1px blue.500"
              }}
            />
          </InputGroup>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FontAwesomeIcon} icon="lock" color="gray.300" />
            </InputLeftElement>
            <Input
              type="password"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              size="lg"
              borderRadius="md"
              _focus={{
                borderColor: "blue.500",
                boxShadow: "0 0 0 1px blue.500"
              }}
            />
          </InputGroup>

          <Button
            type="submit"
            colorScheme="blue"
            w="100%"
            size="lg"
            leftIcon={<FontAwesomeIcon icon="sign-in-alt" />}
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "lg",
            }}
            transition="all 0.2s"
          >
            {isLogin ? 'Se connecter' : "S'inscrire"}
          </Button>

          <Divider />

          <Button
            w="100%"
            onClick={handleGoogleSignIn}
            leftIcon={<FontAwesomeIcon icon={['fab', 'google']} />}
            variant="outline"
            size="lg"
            _hover={{
              bg: "gray.50",
              transform: "translateY(-2px)",
              boxShadow: "md",
            }}
            transition="all 0.2s"
          >
            Continuer avec Google
          </Button>

          <Button
            variant="link"
            onClick={() => setIsLogin(!isLogin)}
            color="blue.500"
            _hover={{ textDecoration: "none", color: "blue.600" }}
          >
            {isLogin
              ? "Pas encore de compte ? S'inscrire"
              : 'Déjà un compte ? Se connecter'}
          </Button>
        </VStack>
      </Box>
    </Container>
  );
};

export default AuthForm;
