import {
  Box,
  VStack,
  Heading,
  Text,
  Container,
  UnorderedList,
  ListItem,
  Divider,
} from '@chakra-ui/react';

const TermsOfService = () => {
  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="xl" mb={6}>
          Conditions Générales d'Utilisation (CGU)
        </Heading>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            1. Objet
          </Heading>
          <Text>
            Les présentes Conditions Générales d'Utilisation (CGU) ont pour objet de définir les modalités et conditions d'accès et d'utilisation du site CoTaxiage.com (ci-après désigné "le Site"). En accédant au Site, vous reconnaissez avoir pris connaissance des présentes CGU et les accepter sans réserve.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            2. Identité de l'éditeur
          </Heading>
          <Text>
            Le Site est édité par Soudane Chokri, domicilié au rue Ferdinand Chartier, Saint-Cloud, Hauts-de-Seine (92).
            Pour toute question ou demande, vous pouvez nous contacter à l'adresse e-mail suivante : support@cotaxiage.fr.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            3. Accès et utilisation du Site
          </Heading>
          <VStack spacing={4} align="stretch">
            <Box>
              <Heading as="h3" size="md" mb={2}>
                3.1 Gratuité
              </Heading>
              <Text>
                Le Site est fourni gratuitement et ne requiert aucun abonnement ni paiement pour accéder à ses contenus ou fonctionnalités.
              </Text>
            </Box>
            <Box>
              <Heading as="h3" size="md" mb={2}>
                3.2 Conditions d'accès
              </Heading>
              <Text>
                Le Site est accessible 24h/24 et 7j/7, sauf en cas d'interruptions nécessaires à sa maintenance, mise à jour ou pour toute autre raison technique. Nous nous réservons le droit de suspendre ou d'arrêter l'accès au Site à tout moment, sans préavis.
              </Text>
            </Box>
            <Box>
              <Heading as="h3" size="md" mb={2}>
                3.3 Obligations de l'utilisateur
              </Heading>
              <Text>
                L'utilisateur s'engage à utiliser le Site dans le respect des lois et règlements en vigueur en France. Toute utilisation abusive, illégale ou détournée est interdite, notamment toute action visant à compromettre la sécurité, l'intégrité ou la disponibilité du Site.
              </Text>
            </Box>
          </VStack>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            4. Limitation de responsabilité
          </Heading>
          <VStack spacing={4} align="stretch">
            <Box>
              <Heading as="h3" size="md" mb={2}>
                4.1 Utilisation sous votre responsabilité
              </Heading>
              <Text>
                CoTaxiage.com est fourni "en l'état", sans garantie d'aucune nature, expresse ou implicite. L'utilisateur navigue sur le Site à ses propres risques.
              </Text>
            </Box>
            <Box>
              <Heading as="h3" size="md" mb={2}>
                4.2 Absence de garantie sur le contenu
              </Heading>
              <Text>
                Les informations et contenus disponibles sur le Site sont fournis à titre indicatif. Nous ne garantissons pas leur exactitude, exhaustivité ou actualité. L'utilisateur est seul responsable de l'utilisation qu'il fait des informations.
              </Text>
            </Box>
            <Box>
              <Heading as="h3" size="md" mb={2}>
                4.3 Exclusion de responsabilité
              </Heading>
              <Text mb={2}>L'éditeur décline toute responsabilité pour :</Text>
              <UnorderedList pl={4}>
                <ListItem>Les interruptions ou dysfonctionnements du Site.</ListItem>
                <ListItem>Les pertes de données ou dommages indirects liés à l'utilisation ou à l'impossibilité d'utiliser le Site.</ListItem>
                <ListItem>Les liens hypertextes renvoyant vers des sites tiers, dont le contenu et les services relèvent de la responsabilité exclusive desdits sites.</ListItem>
              </UnorderedList>
            </Box>
          </VStack>
        </Box>

        <Divider my={6} />

        <Text color="gray.600" fontSize="sm">
          Dernière mise à jour : 08 Décembre 2024
        </Text>
      </VStack>
    </Container>
  );
};

export default TermsOfService;
