import { useState, useEffect } from 'react';
import {
  VStack,
  Box,
  Text,
  Badge,
  SimpleGrid,
  Button,
  useToast,
  Heading,
  HStack,
  Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Flex,
  Spacer,
  IconButton,
  Divider,
  Stack,
  Wrap,
  WrapItem,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faShare,
  faTrash,
  faLocationArrow,
  faEdit,
  faCheck,
  faTimes,
  faExclamationTriangle,
  faMapMarkerAlt,
  faCalendar,
  faUsers,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../../firebase.config';
import { AIRPORT_PRICES } from '../../../config/prices';
import ShareModal from '../../common/ShareModal';
import NavigationModal from '../../common/NavigationModal';
import { useNavigate } from 'react-router-dom';

const FIXED_RATES = AIRPORT_PRICES;

const RideHistory = ({ user }) => {
  const [rides, setRides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [sortBy, setSortBy] = useState('date');
  const [hasMore, setHasMore] = useState(true);
  const [selectedRide, setSelectedRide] = useState(null);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [shareUrl, setShareUrl] = useState('');
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose, cancelRef } = useDisclosure();
  const { isOpen: isParticipantOpen, onOpen: onParticipantOpen, onClose: onParticipantClose } = useDisclosure();
  const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();
  const { isOpen: isNavigationOpen, onOpen: onNavigationOpen, onClose: onNavigationClose } = useDisclosure();
  const toast = useToast();
  const RIDES_PER_PAGE = 5;
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const navigate = useNavigate();

  useEffect(() => {
    fetchRides();
  }, [filter, sortBy]);

  const fetchRides = async (loadMore = false) => {
    try {
      setLoading(true);
      const ridesRef = collection(db, 'rides');
      
      // Requête simplifiée utilisant uniquement createdBy
      let q = query(
        ridesRef,
        where('createdBy', '==', user.email)
      );

      const querySnapshot = await getDocs(q);
      const ridesData = [];
      
      // Filtrage et tri côté client
      const now = new Date();
      querySnapshot.forEach((doc) => {
        const rideData = { id: doc.id, ...doc.data() };
        const rideDate = new Date(rideData.date);
        
        // Appliquer le filtre côté client
        if (
          filter === 'all' ||
          (filter === 'upcoming' && rideDate >= now) ||
          (filter === 'past' && rideDate < now)
        ) {
          // Calculer le coût par personne
          const totalParticipants = rideData.participants?.length || 1;
          rideData.pricePerPerson = Math.round(rideData.price / totalParticipants);
          ridesData.push(rideData);
        }
      });

      // Tri côté client
      ridesData.sort((a, b) => {
        if (sortBy === 'date') {
          return new Date(b.date) - new Date(a.date);
        } else if (sortBy === 'price') {
          return b.price - a.price;
        } else if (sortBy === 'participants') {
          return (b.participants?.length || 1) - (a.participants?.length || 1);
        }
      });

      // Pagination manuelle côté client
      const startIndex = loadMore ? rides.length : 0;
      const paginatedRides = ridesData.slice(startIndex, startIndex + RIDES_PER_PAGE);
      
      setHasMore(ridesData.length > startIndex + RIDES_PER_PAGE);

      if (loadMore) {
        setRides([...rides, ...paginatedRides]);
      } else {
        setRides(paginatedRides);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des trajets:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de charger vos trajets',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleShare = (ride) => {
    setSelectedRide(ride);
    // Créer l'URL de partage
    const shareUrl = `${window.location.origin}/rides/${ride.id}`;
    setShareUrl(shareUrl);
    onShareOpen();
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast({
        title: "Lien copié !",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Erreur lors de la copie",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    if (!selectedRide) return;

    try {
      await deleteDoc(doc(db, 'rides', selectedRide.id));
      setRides(rides.filter(ride => ride.id !== selectedRide.id));
      toast({
        title: 'Trajet supprimé',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le trajet',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      onDeleteClose();
      setSelectedRide(null);
    }
  };

  const confirmDelete = (ride) => {
    setSelectedRide(ride);
    onDeleteOpen();
  };

  const handleRemoveParticipant = async () => {
    if (!selectedRide || !selectedParticipant) return;
    
    try {
      const rideRef = doc(db, 'rides', selectedRide.id);
      const updatedParticipants = selectedRide.participants.filter(
        email => email !== selectedParticipant
      );
    
      await updateDoc(rideRef, {
        participants: updatedParticipants
      });
    
      // Mettre à jour l'état local
      setRides(rides.map(ride => 
        ride.id === selectedRide.id 
          ? { ...ride, participants: updatedParticipants }
          : ride
      ));
    
      toast({
        title: 'Participant retiré',
        description: 'Le participant a été retiré du trajet avec succès',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors du retrait du participant:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de retirer le participant',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      onParticipantClose();
      setSelectedParticipant(null);
    }
  };

  const confirmRemoveParticipant = (ride, participant) => {
    setSelectedRide(ride);
    setSelectedParticipant(participant);
    onParticipantOpen();
  };

  const getRideStats = (ride) => {
    const participants = ride.participants?.length || 1;
    const maxParticipants = 4;
    const occupancyRate = (participants / maxParticipants) * 100;
    const pricePerPerson = Math.round(ride.price / participants);
    const totalRevenue = ride.price;
      
    return {
      participants,
      occupancyRate,
      pricePerPerson,
      totalRevenue
    };
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.error('Date invalide:', dateString);
        return 'Date invalide';
      }

      const options = { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      };

      // Formater la date en français
      const formatter = new Intl.DateTimeFormat('fr-FR', options);
      return formatter.format(date)
        .replace(':', ' h ') // Remplacer : par h pour le format français
        .replace(/^./, str => str.toUpperCase()); // Première lettre en majuscule
    } catch (error) {
      console.error('Erreur lors du formatage de la date:', error);
      return 'Date invalide';
    }
  };

  const getStatusColor = (date) => {
    const now = new Date();
    const rideDate = new Date(date);
    
    if (rideDate < now) return 'gray';
    if (rideDate > now) return 'green';
    return 'blue';
  };

  const formatStation = (stationId) => {
    const stations = {
      CDG: 'Roissy Charles de Gaulle',
      ORLY: 'Orly',
      GARE_DU_NORD: 'Gare du Nord',
      GARE_DE_LYON: 'Gare de Lyon',
      GARE_MONTPARNASSE: 'Gare Montparnasse',
      GARE_DE_LEST: "Gare de l'Est"
    };
    
    return stations[stationId] || stationId;
  };

  const handleMessage = (ride) => {
    navigate(`/messages/${ride.id}`);
  };

  return (
    <VStack spacing={6} align="stretch" w="100%">
      {/* En-tête avec filtres */}
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        align="center"
        mb={6}
        bg={useColorModeValue('gray.50', 'gray.700')}
        p={4}
        borderRadius="lg"
      >
        <HStack spacing={4} flex={1}>
          <Select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            maxW="200px"
            size="sm"
          >
            <option value="all">Tous les trajets</option>
            <option value="upcoming">Trajets à venir</option>
            <option value="past">Trajets passés</option>
          </Select>
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            maxW="200px"
            size="sm"
          >
            <option value="date">Trier par date</option>
            <option value="price">Trier par prix</option>
            <option value="participants">Trier par participants</option>
          </Select>
        </HStack>
      </Stack>

      {/* Liste des trajets */}
      <VStack spacing={6} align="stretch">
        {rides.length === 0 && !loading ? (
          <Box 
            textAlign="center" 
            py={10}
            px={4}
            borderWidth="1px"
            borderRadius="lg"
            borderStyle="dashed"
          >
            <Text color="gray.500" mb={2}>
              <FontAwesomeIcon icon="info-circle" size="2x" />
            </Text>
            <Text>Aucun trajet trouvé</Text>
            {filter !== 'all' && (
              <Button
                mt={4}
                size="sm"
                variant="outline"
                onClick={() => setFilter('all')}
              >
                Voir tous les trajets
              </Button>
            )}
          </Box>
        ) : (
          <>
            {rides.map((ride) => (
              <Box
                key={ride.id}
                p={{ base: 3, md: 4 }}
                borderWidth="1px"
                borderRadius="lg"
                shadow="sm"
                bg={bgColor}
                borderColor={borderColor}
              >
                {/* Contenu existant du trajet */}
                <Stack
                  direction={{ base: "column", md: "row" }}
                  align={{ base: "stretch", md: "center" }}
                  spacing={{ base: 3, md: 4 }}
                >
                  <Box flex="1">
                    <Wrap spacing={2} mb={2}>
                      <WrapItem>
                        <Badge colorScheme={getStatusColor(ride.date)}>
                          {new Date(ride.date) > new Date() ? 'À venir' : 'Passé'}
                        </Badge>
                      </WrapItem>
                      <WrapItem>
                        <Tooltip label={`${getRideStats(ride).occupancyRate}% de remplissage`}>
                          <Badge colorScheme="blue">
                            {4 - (ride.participants?.length || 1)} place(s) disponible(s)
                          </Badge>
                        </Tooltip>
                      </WrapItem>
                      <WrapItem>
                        <Tooltip label={`Revenu total: ${getRideStats(ride).totalRevenue}€`}>
                          <Badge colorScheme="green">
                            {getRideStats(ride).pricePerPerson}€ par personne
                          </Badge>
                        </Tooltip>
                      </WrapItem>
                    </Wrap>

                    <Text 
                      fontSize={{ base: "md", md: "lg" }} 
                      fontWeight="bold" 
                      mb={2}
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      gap={2}
                    >
                      <Box as="span" display="inline-flex" alignItems="center">
                        <FontAwesomeIcon icon="map-marker-alt" />
                        <Text as="span" ml={2}>{formatStation(ride.departure)}</Text>
                      </Box>
                      <Text as="span" mx={2}>→</Text>
                      <Box as="span" display="inline-flex" alignItems="center">
                        <Text as="span">{formatStation(ride.destination)}</Text>
                      </Box>
                    </Text>

                    <Stack
                      direction={{ base: "column", sm: "row" }}
                      spacing={{ base: 1, sm: 4 }}
                      mb={2}
                      color="gray.600"
                    >
                      <Text display="flex" alignItems="center">
                        <FontAwesomeIcon icon="calendar" />
                        <Text as="span" ml={2}>{formatDate(ride.date)}</Text>
                      </Text>
                      <Text display="flex" alignItems="center">
                        <FontAwesomeIcon icon="users" />
                        <Text as="span" ml={2}>{getRideStats(ride).participants} participant(s)</Text>
                      </Text>
                    </Stack>

                    <Wrap spacing={2} mt={2}>
                      {ride.participants?.map((participant, index) => (
                        <WrapItem key={index}>
                          <Badge 
                            colorScheme="purple"
                            display="flex"
                            alignItems="center"
                          >
                            {participant === user.email ? 'Vous' : participant}
                            {ride.createdBy === user.email && participant !== user.email && (
                              <IconButton
                                aria-label="Retirer le participant"
                                icon={<FontAwesomeIcon icon="times" />}
                                size="xs"
                                variant="ghost"
                                ml={1}
                                onClick={() => confirmRemoveParticipant(ride, participant)}
                              />
                            )}
                          </Badge>
                        </WrapItem>
                      ))}
                    </Wrap>

                  </Box>

                  <Stack
                    direction={{ base: "row", md: "column" }}
                    spacing={2}
                    justify={{ base: "center", md: "flex-start" }}
                    align="center"
                  >
                    <HStack spacing={2}>
                      <Tooltip label="Partager">
                        <IconButton
                          size="sm"
                          icon={<FontAwesomeIcon icon={faShare} />}
                          onClick={() => {
                            setSelectedRide(ride);
                            onShareOpen();
                          }}
                          colorScheme="blue"
                          variant="ghost"
                        />
                      </Tooltip>

                      <Tooltip label="Rejoindre le point de départ">
                        <IconButton
                          size="sm"
                          icon={<FontAwesomeIcon icon={faLocationArrow} />}
                          onClick={() => {
                            setSelectedRide(ride);
                            onNavigationOpen();
                          }}
                          colorScheme="purple"
                          variant="ghost"
                        />
                      </Tooltip>

                      <Tooltip label="Messages du trajet">
                        <IconButton
                          size="sm"
                          icon={<FontAwesomeIcon icon={faComment} />}
                          onClick={() => navigate(`/messages/${ride.id}`)}
                          colorScheme="green"
                          variant="ghost"
                        />
                      </Tooltip>

                      {ride.createdBy === user.email && (
                        <Tooltip label="Supprimer">
                          <IconButton
                            size="sm"
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            onClick={() => confirmDelete(ride)}
                            colorScheme="red"
                            variant="ghost"
                          />
                        </Tooltip>
                      )}
                    </HStack>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </>
        )}

      {hasMore && (
        <Button
          onClick={() => fetchRides(true)}
          isLoading={loading}
          mt={4}
          w="100%"
          variant="outline"
        >
          Charger plus de trajets
        </Button>
      )}
      </VStack>

      {/* Dialogues modaux */}
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le trajet
            </AlertDialogHeader>
            <AlertDialogBody>
              Êtes-vous sûr ? Cette action ne peut pas être annulée.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isParticipantOpen}
        leastDestructiveRef={cancelRef}
        onClose={onParticipantClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Retirer le participant ?</AlertDialogHeader>
            <AlertDialogBody>
              Êtes-vous sûr de vouloir retirer ce participant du trajet ?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onParticipantClose}>Annuler</Button>
              <Button colorScheme="red" onClick={handleRemoveParticipant} ml={3}>
                Retirer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <ShareModal
        isOpen={isShareOpen}
        onClose={onShareClose}
        ride={selectedRide}
      />

      <NavigationModal
        isOpen={isNavigationOpen}
        onClose={onNavigationClose}
        ride={selectedRide}
      />
    </VStack>
  );
};

export default RideHistory;
