import { initializeApp, getApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAQvS9ibMTSiEX4TOfCVnRtCrQZKmIL8V0",
  authDomain: "cotaxiage-v1.firebaseapp.com",
  projectId: "cotaxiage-v1",
  storageBucket: "cotaxiage-v1.appspot.com",
  messagingSenderId: "251661938541",
  appId: "1:251661938541:web:01a3baf24d8bc90a733464",
  measurementId: "G-NNBFFQFCPX",
  // Ajout de l'URL de la Realtime Database
  databaseURL: "https://cotaxiage-v1-default-rtdb.europe-west1.firebasedatabase.app"
};

// Vérifier si Firebase n'est pas déjà initialisé
let app;
try {
  app = initializeApp(firebaseConfig);
} catch (error) {
  if (error.code === 'app/duplicate-app') {
    // Si l'app existe déjà, utiliser celle existante
    app = getApp();
  } else {
    throw error;
  }
}

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const database = getDatabase(app);

export { db, auth, storage, database };
