import { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  useToast,
  VStack,
  Image,
  Input,
  Text,
  Progress,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTrash } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { uploadProfilePhoto, deleteProfilePhoto } from '../../../services/profile.service';
import { buildImageUrl } from '../../../services/cloudinary.service';
import { useAuth } from '../../../contexts/AuthContext';

const MotionBox = motion(Box);

const ProfilePhotoUpload = () => {
  const [previewURL, setPreviewURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();
  const toast = useToast();

  useEffect(() => {
    if (user?.photoURL) {
      setPreviewURL(user.photoURL);
    }
  }, [user]);

  const handlePhotoSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      // 1. Créer et afficher la prévisualisation
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(file);

      // 2. Commencer l'upload
      setIsLoading(true);
      setUploadProgress(0);

      const photoURL = await uploadProfilePhoto(file, (progress) => {
        setUploadProgress(progress);
      });

      // 3. Mettre à jour avec l'URL finale
      setPreviewURL(photoURL);
      
      toast({
        title: 'Succès',
        description: 'Photo mise à jour',
        status: 'success',
        duration: 3000,
      });

    } catch (error) {
      console.error('Erreur:', error);
      setPreviewURL(null);
      toast({
        title: 'Erreur',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      // Attendre un peu avant de cacher la barre de progression
      setTimeout(() => {
        setIsLoading(false);
        setUploadProgress(0);
      }, 500);
    }
  };

  const handleDeletePhoto = async () => {
    try {
      setIsLoading(true);
      await deleteProfilePhoto();
      setPreviewURL(null);
      onClose();
      toast({
        title: 'Succès',
        description: 'Photo supprimée avec succès',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer la photo',
        status: 'error',
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VStack spacing={4} align="center" w="full">
      <MotionBox
        position="relative"
        whileHover={{ scale: 1.05 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          w="150px"
          h="150px"
          borderRadius="full"
          overflow="hidden"
          position="relative"
        >
          {previewURL ? (
            <Image
              src={previewURL.includes('cloudinary') ? buildImageUrl(previewURL, { width: 300, height: 300 }) : previewURL}
              alt="Photo de profil"
              w="full"
              h="full"
              objectFit="cover"
              loading="lazy"
            />
          ) : (
            <Box
              w="full"
              h="full"
              bg="gray.200"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <FontAwesomeIcon icon={faImage} size="2x" color="gray" />
            </Box>
          )}

          {/* Overlay avec boutons */}
          <Box
            position="absolute"
            top="0"
            left="0"
            w="full"
            h="full"
            bg="blackAlpha.600"
            opacity={isHovered ? 1 : 0}
            transition="opacity 0.2s"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Input
              type="file"
              accept="image/*"
              onChange={handlePhotoSelect}
              display="none"
              id="photo-upload"
              isDisabled={isLoading}
            />
            <label htmlFor="photo-upload">
              <IconButton
                as="span"
                aria-label="Changer la photo"
                icon={<FontAwesomeIcon icon={faImage} />}
                size="sm"
                colorScheme="blue"
                cursor="pointer"
                isDisabled={isLoading}
              />
            </label>
            {previewURL && (
              <IconButton
                aria-label="Supprimer la photo"
                icon={<FontAwesomeIcon icon={faTrash} />}
                size="sm"
                colorScheme="red"
                onClick={onOpen}
                isDisabled={isLoading}
              />
            )}
          </Box>
        </Box>

        {/* Barre de progression */}
        {isLoading && (
          <Box position="absolute" bottom="-30px" left="0" right="0">
            <Progress
              value={uploadProgress}
              size="xs"
              colorScheme="blue"
              hasStripe
              isAnimated
              borderRadius="full"
            />
            <Text fontSize="xs" textAlign="center" mt={1}>
              {uploadProgress}%
            </Text>
          </Box>
        )}
      </MotionBox>

      {/* Modal de confirmation de suppression */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmer la suppression</ModalHeader>
          <ModalBody>
            Êtes-vous sûr de vouloir supprimer votre photo de profil ?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button colorScheme="red" onClick={handleDeletePhoto} isLoading={isLoading}>
              Supprimer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ProfilePhotoUpload;
