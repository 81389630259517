import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  Text,
  Button,
  Box,
  Divider,
  Badge,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const StationSelectionModal = ({
  isOpen,
  onClose,
  nearestStation,
  stations,
  onSelectStation,
  distances,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sélection de la station</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={4} align="stretch">
            {/* Station la plus proche */}
            {nearestStation && (
              <>
                <Button
                  colorScheme="green"
                  size="lg"
                  leftIcon={<FontAwesomeIcon icon={faLocationDot} />}
                  onClick={() => {
                    onSelectStation(nearestStation.id);
                    onClose();
                  }}
                >
                  <VStack align="start" spacing={1}>
                    <Text>Station la plus proche :</Text>
                    <HStack>
                      <Text fontWeight="bold">{nearestStation.name}</Text>
                      {distances[nearestStation.id] && (
                        <Badge colorScheme="green">
                          {distances[nearestStation.id].toFixed(1)} km
                        </Badge>
                      )}
                    </HStack>
                  </VStack>
                </Button>
                <Divider />
              </>
            )}

            {/* Liste des autres stations */}
            <VStack spacing={2} align="stretch">
              {stations
                .filter(station => !nearestStation || station.id !== nearestStation.id)
                .sort((a, b) => (distances[a.id] || 0) - (distances[b.id] || 0))
                .map(station => (
                  <Button
                    key={station.id}
                    variant="outline"
                    size="lg"
                    leftIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                    onClick={() => {
                      onSelectStation(station.id);
                      onClose();
                    }}
                    justifyContent="space-between"
                  >
                    <Text>{station.name}</Text>
                    {distances[station.id] && (
                      <Badge colorScheme="blue">
                        {distances[station.id].toFixed(1)} km
                      </Badge>
                    )}
                  </Button>
                ))}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StationSelectionModal;
