import { useState } from 'react';
import {
  VStack,
  Button,
  Box,
  useToast,
  Heading,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepDescription,
  StepSeparator,
  useSteps,
  HStack,
} from '@chakra-ui/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlane,
  faTaxi,
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faClock,
  faUsers,
  faComment,
  faCheck,
  faCircleNotch,
  faShare,
  faUserPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { calculatePricePerPerson } from '../../config/prices';
import SelectRideType from './steps/SelectRideType';
import SelectStation from './steps/SelectStation';
import SelectDateTime from './steps/SelectDateTime';
import RideList from './RideList';
import RideSummary from './steps/RideSummary';
import SelectSeats from './steps/SelectSeats';

library.add(
  faPlane,
  faTaxi,
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faClock,
  faUsers,
  faComment,
  faCheck,
  faCircleNotch,
  faShare,
  faUserPlus,
  faTrash
);

const steps = [
  { title: 'Type de trajet', description: 'Depuis/vers aéroport' },
  { title: 'Départ', description: 'Point de départ' },
  { title: 'Destination', description: 'Point d\'arrivée' },
  { title: 'Date & Heure', description: 'Planification' },
  { title: 'Places', description: 'Nombre de places' },
  { title: 'Résultats', description: 'Trajets disponibles' }
];

const SearchRide = () => {
  const toast = useToast();
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [searchParams, setSearchParams] = useState({
    type: '',
    departure: '',
    destination: '',
    date: '',
    time: '',
    seats: 1,
  });
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRide, setSelectedRide] = useState(null);

  const handleSearch = async (params = searchParams) => {
    setLoading(true);
    try {
      console.log('=== Début de la recherche ===');
      console.log('Paramètres de recherche:', params);
      console.log('Date format:', params.date);
      console.log('Time format:', params.time);
      
      const ridesRef = collection(db, 'rides');
      let conditions = [];
      
      if (params.departure) {
        conditions.push(where('departure', '==', params.departure));
      }
      if (params.destination) {
        conditions.push(where('destination', '==', params.destination));
      }

      console.log('Conditions de recherche:', conditions);
      let q = query(ridesRef, ...conditions);
      
      const querySnapshot = await getDocs(q);
      console.log('Nombre de résultats bruts:', querySnapshot.size);

      const filteredResults = [];
      querySnapshot.forEach((doc) => {
        const rideData = { id: doc.id, ...doc.data() };
        console.log('Trajet trouvé:', rideData);
        console.log('Format de date du trajet:', rideData.date);
        console.log('Format d\'heure du trajet:', rideData.time);

        // Vérifier si nous avons une date de recherche
        if (!params.date || !params.time) {
          console.log('Date ou heure de recherche manquante');
          return;
        }

        try {
          // Normaliser la date du trajet
          let rideDate = rideData.date;
          if (rideDate.includes('T')) {
            // Créer un objet Date et le convertir au fuseau horaire local
            const dateObj = new Date(rideData.date);
            rideDate = dateObj.toISOString().split('T')[0];
          }

          // Extraire l'heure du trajet
          let rideTime = rideData.time;
          if (!rideTime && rideData.date && rideData.date.includes('T')) {
            // Si l'heure n'est pas dans le champ time, l'extraire de la date
            const dateObj = new Date(rideData.date);
            rideTime = `${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
          }

          if (!rideTime) {
            console.log('Heure du trajet non disponible');
            return;
          }

          // Convertir les heures en minutes pour la comparaison
          const searchTimeMinutes = parseInt(params.time.split(':')[0]) * 60 + parseInt(params.time.split(':')[1]);
          const rideTimeMinutes = parseInt(rideTime.split(':')[0]) * 60 + parseInt(rideTime.split(':')[1]);
          
          // Permettre une différence de 30 minutes
          const timeDifference = Math.abs(searchTimeMinutes - rideTimeMinutes);
          
          // Vérifier si les dates correspondent et si l'heure est dans la plage de 30 minutes
          if (rideDate === params.date && timeDifference <= 30) {
            // Vérifier que le trajet n'est pas complet et a assez de places
            const remainingSeats = 4 - rideData.participants.length;
            if (remainingSeats > 0 && remainingSeats >= params.seats) {
              console.log('Trajet correspondant trouvé - Même date et heure proche');
              filteredResults.push(rideData);
            }
          }
        } catch (error) {
          console.error('Erreur lors de la comparaison des dates:', error);
        }
      });

      console.log('Résultats filtrés:', filteredResults);
      setResults(filteredResults);

      if (filteredResults.length === 0) {
        toast({
          title: 'Aucun trajet trouvé',
          description: 'Essayez de modifier vos critères de recherche',
          status: 'info',
          duration: 5000,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la recherche:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de rechercher les trajets',
        status: 'error',
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 2) {
      handleSearch(searchParams);
    }
    goToNext();
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <SelectRideType
            onChange={(type) => {
              setSearchParams({ ...searchParams, type });
              goToNext();
            }}
          />
        );
      case 1:
        return (
          <SelectStation
            type="departure"
            rideType={searchParams.type}
            value={searchParams.departure}
            onChange={(departure) => {
              setSearchParams({ ...searchParams, departure });
              goToNext();
            }}
          />
        );
      case 2:
        return (
          <SelectStation
            type="destination"
            rideType={searchParams.type}
            value={searchParams.destination}
            onChange={(destination) => {
              setSearchParams({ ...searchParams, destination });
              goToNext();
            }}
          />
        );
      case 3:
        return (
          <SelectDateTime
            date={searchParams.date}
            time={searchParams.time}
            onDateChange={(date) => setSearchParams({ ...searchParams, date })}
            onTimeChange={(time) => setSearchParams({ ...searchParams, time })}
            onNext={({ date, time }) => {
              const updatedParams = { ...searchParams, date, time };
              setSearchParams(updatedParams);
              goToNext();
            }}
            onBack={goToPrevious}
          />
        );
      case 4:
        return (
          <SelectSeats
            onNext={({ seats }) => {
              const updatedParams = { ...searchParams, seats };
              setSearchParams(updatedParams);
              handleSearch(updatedParams);
              goToNext();
            }}
            onBack={goToPrevious}
          />
        );
      case 5:
        return (
          <RideList
            rides={results}
            loading={loading}
            onSelect={(ride) => setSelectedRide(ride)}
          />
        );
      default:
        return null;
    }
  };

  const canGoNext = () => {
    switch (activeStep) {
      case 0:
        return !!searchParams.type;
      case 1:
        return !!searchParams.departure;
      case 2:
        return !!searchParams.destination;
      case 3:
        return !!searchParams.date && !!searchParams.time;
      case 4:
        return !!searchParams.seats;
      case 5:
        return !!selectedRide;
      default:
        return false;
    }
  };

  return (
    <Box w="100%" maxW="600px" mx="auto" p={4}>
      <VStack spacing={8} align="stretch">
        <Stepper index={activeStep} orientation="horizontal" mb={8}>
          {steps.map((step, index) => (
            <Step key={index} flexDirection="column">
              <StepIndicator>
                <StepStatus
                  complete={<Box w={2} h={2} bg="blue.500" borderRadius="full" />}
                  incomplete={<Box w={2} h={2} bg="gray.300" borderRadius="full" />}
                  active={<Box w={2} h={2} bg="blue.500" borderRadius="full" />}
                />
              </StepIndicator>

              <Box display={{ base: 'none', sm: 'block' }} textAlign="center">
                <StepTitle fontSize={{ base: "sm", md: "md" }} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                  {step.title}
                </StepTitle>
                <StepDescription fontSize={{ base: "xs", md: "sm" }} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                  {step.description}
                </StepDescription>
              </Box>
            </Step>
          ))}
        </Stepper>

        <VStack spacing={4} mt={4}>
          {renderStep()}

          <HStack spacing={4} w="100%" justify="center">
            {activeStep > 0 && activeStep !== 3 && activeStep !== 4 && (
              <Button
                variant="ghost"
                onClick={goToPrevious}
                leftIcon={<FontAwesomeIcon icon="arrow-left" />}
              >
                Retour
              </Button>
            )}

            {activeStep === steps.length - 1 && results.length === 0 && (
              <Button
                variant="ghost"
                onClick={() => {
                  setResults([]);
                  setSelectedRide(null);
                  goToPrevious();
                }}
                leftIcon={<FontAwesomeIcon icon="arrow-left" />}
              >
                Retour à la recherche
              </Button>
            )}

            {activeStep === steps.length - 1 && selectedRide && (
              <Button
                colorScheme="green"
                onClick={() => {/* Logique de réservation */}}
                leftIcon={<FontAwesomeIcon icon="check" />}
              >
                Réserver ce trajet
              </Button>
            )}
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};

export default SearchRide;
