import React from 'react';
import {
  VStack,
  HStack,
  Button,
  Icon,
  Text,
  useToast,
  Image,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';

const navigationApps = [
  {
    name: 'Google Maps',
    scheme: 'comgooglemaps://',
    webUrl: 'https://www.google.com/maps/dir/?api=1',
    icon: '/icons/google-maps.png',
  },
  {
    name: 'Waze',
    scheme: 'waze://',
    webUrl: 'https://www.waze.com/ul',
    icon: '/icons/waze.png',
  },
  {
    name: 'Plans',
    scheme: 'maps://',
    webUrl: 'http://maps.apple.com/',
    icon: '/icons/apple-maps.png',
  },
  {
    name: 'Citymapper',
    scheme: 'citymapper://',
    webUrl: 'https://citymapper.com/directions',
    icon: '/icons/citymapper.png',
  }
];

const NavigationApps = ({ 
  destination, 
  address,
  latitude,
  longitude,
  onNavigate 
}) => {
  const toast = useToast();

  const handleNavigation = (app) => {
    // Construire l'URL avec les coordonnées
    const coords = `${latitude},${longitude}`;
    let url;

    switch (app.name) {
      case 'Google Maps':
        url = `${app.webUrl}&destination=${coords}&destination_place_id=${address}`;
        break;
      case 'Waze':
        url = `${app.webUrl}?ll=${coords}&navigate=yes`;
        break;
      case 'Plans':
        url = `${app.scheme}?daddr=${coords}`;
        break;
      case 'Citymapper':
        url = `${app.webUrl}?endcoord=${coords}&endname=${encodeURIComponent(address)}`;
        break;
      default:
        url = `${app.webUrl}?q=${coords}`;
    }

    // Tenter d'ouvrir l'app native d'abord
    try {
      window.location.href = url.replace('https://', app.scheme);
      
      // Callback si fourni
      if (onNavigate) {
        onNavigate(app.name);
      }
      
      toast({
        title: "Navigation lancée",
        description: `Ouverture de ${app.name}...`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Fallback vers version web
      window.open(url, '_blank');
      
      toast({
        title: "Application non installée",
        description: `Ouverture de la version web de ${app.name}`,
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={4} align="stretch" w="100%">
      <Text fontSize="sm" color="gray.600">
        Choisissez votre application de navigation pour rejoindre {destination}
      </Text>
      
      {navigationApps.map((app) => (
        <Button
          key={app.name}
          onClick={() => handleNavigation(app)}
          size="lg"
          variant="outline"
          w="100%"
        >
          <HStack spacing={4} width="100%" justifyContent="flex-start">
            <Image
              src={app.icon}
              alt={app.name}
              boxSize="24px"
              fallback={<Icon as={FontAwesomeIcon} icon={faLocationArrow} />}
            />
            <Text>{app.name}</Text>
          </HStack>
        </Button>
      ))}
    </VStack>
  );
};

export default NavigationApps;
